import React from 'react';

import { ApolloConsumer } from '@apollo/client';

export default Component => props =>
  (
    <ApolloConsumer>
      {client => <Component client={client} {...props} />}
    </ApolloConsumer>
  );
