import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { LoginPage } from '../../pages/Login/Login';

const LandingPage = () => {
  const { user } = useAuth();
  return (
    <React.Fragment>
      {!user ? <LoginPage /> : <Navigate to="/dashboard" replace />}
    </React.Fragment>
  );
};

export default LandingPage;
