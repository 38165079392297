import React from 'react';
import { FormHelperText, Grid, Typography } from '@mui/material';
import {
  CustomSelect,
  CustomTextField
} from '../../hoc/CustomComponents';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  Error_msg: {
    color: '#ED5B4F !important',
    height: '2px',
    font: 'normal normal 500 10px/16px Poppins',
    letterSpacing: '0px',
    opacity: '1',
    paddingTop: '0px',
    paddingLeft: '16px'
  }
});

function CustomerDetails(props) {
  const classes = useStyles();
  const {
    onChange,
    handleCountryChange,
    handleStateChange,
    handleCityChange,
    handleBankChange,
    checkCustomerNameValidity,
    checkCustomerEmailValidity,
    checkPhoneNoValidity,
    checkAccountNumberValidity,
    checkBankNameValidity,
    checkAccountHolderValidity,
    checkIfscValidity,
    checkGstValidity,
    checkPanNumberValidity,
    checkCountryValidity,
    checkStateValidity,
    checkCityValidity,
    checkPinCodeValidity
  } = props;
  const {
    isEditOfExistingCustomer,
    customerName,
    userName,
    password,
    oemCode,
    emailId,
    address1,
    address2,
    phoneNumber,
    bankName,
    bankId,
    allBanks,
    accountNumber,
    accountHolder,
    ifsc,
    gstNumber,
    panNumber,
    countryId,
    allCountries,
    stateId,
    allStates,
    allCity,
    cityId,
    cityName,
    pinCode,
    countryName,
    stateName,
    isCustomerNameValid,
    isCustomerEmailValid,
    isPhoneNumberValid,
    isAccountNumberValid,
    isBankNameValid,
    isAccountHolderValid,
    isIfscValid,
    isGstValid,
    isPanNumberValid,
    isCountryValid,
    isStateValid,
    isCityValid,
    isPinCodeValid
  } = props.value;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              style={{
                font: 'normal normal 500 24px/32px poppins',
                letterSpacing: '0px',
                color: '#212121',
                opacity: 1
              }}
            >
              Customer Details - KYC
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={4}>
            <CustomTextField
              value={customerName}
              name="customerName"
              label="Customer Name*"
              placeholder="Enter the Customer Name"
              type="text"
              onChange={onChange}
              error={!isCustomerNameValid}
              onBlur={checkCustomerNameValidity}
            />
            <FormHelperText className={classes.Error_msg}>
              {!isCustomerNameValid ? 'Invalid Customer Name' : ''}
            </FormHelperText>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <CustomTextField
              value={userName}
              disabled={isEditOfExistingCustomer}
              name="userName"
              label="Username*"
              placeholder="Enter the Username"
              type="text"
              onChange={onChange}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <CustomTextField
              value={password}
              disabled={isEditOfExistingCustomer}
              name="password"
              label="Password*"
              placeholder={
                isEditOfExistingCustomer
                  ? '**********'
                  : 'Enter the Password'
              }
              type="password"
              onChange={onChange}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <CustomTextField
              value={oemCode}
              name="oemCode"
              label="OEM Code*"
              placeholder="Enter the OEM Code"
              type="text"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CustomTextField
              value={emailId}
              name="emailId"
              label="Email Address*"
              placeholder="Enter Email Address"
              type="text"
              onChange={onChange}
              error={!isCustomerEmailValid}
              onBlur={checkCustomerEmailValidity}
            />
            <FormHelperText className={classes.Error_msg}>
              {!isCustomerEmailValid ? 'Invalid Email Id' : ''}
            </FormHelperText>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <CustomTextField
              value={address1}
              name="address1"
              label="Address Line1"
              placeholder="Enter the Address"
              type="text"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CustomTextField
              value={address2}
              name="address2"
              label="Address Line2"
              placeholder="Enter the Address"
              type="text"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CustomTextField
              value={phoneNumber}
              name="phoneNumber"
              label="Phone Number"
              placeholder="Enter the Phone Number"
              type="text"
              onChange={onChange}
              error={!isPhoneNumberValid}
              onBlur={checkPhoneNoValidity}
            />
            <FormHelperText className={classes.Error_msg}>
              {!isPhoneNumberValid ? 'Invalid Phone Number' : ''}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CustomSelect
              value={bankId}
              name="bankName"
              label="Bank Name"
              placeholder="Select the Bank"
              options={allBanks}
              handleSelectChange={handleBankChange}
              onChange={onChange}
              inputValue={bankName}
              error={!isBankNameValid}
              onBlur={checkBankNameValidity}
            />
            <FormHelperText className={classes.Error_msg}>
              {!isBankNameValid ? 'Please select a valid bank' : ''}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CustomTextField
              value={accountNumber}
              name="accountNumber"
              label="Account Number"
              placeholder="Enter the Account Number"
              type="text"
              onChange={onChange}
              error={!isAccountNumberValid}
              onBlur={checkAccountNumberValidity}
            />
            <FormHelperText className={classes.Error_msg}>
              {!isAccountNumberValid ? 'Invalid Account Number' : ''}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CustomTextField
              value={accountHolder}
              name="accountHolder"
              label="Account Holder"
              placeholder="Enter the Account Holder Name"
              type="text"
              onChange={onChange}
              error={!isAccountHolderValid}
              onBlur={checkAccountHolderValidity}
            />
            <FormHelperText className={classes.Error_msg}>
              {!isAccountHolderValid
                ? 'Invalid Account Holder Name'
                : ''}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CustomTextField
              value={ifsc}
              name="ifsc"
              label="IFSC"
              placeholder="Enter IFSC"
              type="text"
              onChange={onChange}
              error={!isIfscValid}
              onBlur={checkIfscValidity}
            />
            <FormHelperText className={classes.Error_msg}>
              {!isIfscValid ? 'Invalid IFSC' : ''}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CustomTextField
              value={gstNumber}
              name="gstNumber"
              label="GST Number"
              placeholder="Enter The GST Number"
              type="text"
              onChange={onChange}
              error={!isGstValid}
              onBlur={checkGstValidity}
            />
            <FormHelperText className={classes.Error_msg}>
              {!isGstValid ? 'Invalid GST Number' : ''}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CustomTextField
              value={panNumber}
              name="panNumber"
              label="PAN"
              placeholder="Enter Permanent Account Number"
              type="text"
              onChange={onChange}
              error={!isPanNumberValid}
              onBlur={checkPanNumberValidity}
            />
            <FormHelperText className={classes.Error_msg}>
              {!isPanNumberValid ? 'Invalid PAN Number' : ''}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CustomSelect
              value={countryId}
              label="Country"
              placeholder="Select Country"
              options={allCountries}
              handleSelectChange={handleCountryChange}
              onChange={onChange}
              inputValue={countryName}
              name="countryName"
              error={!isCountryValid}
              onBlur={checkCountryValidity}
            />
            <FormHelperText className={classes.Error_msg}>
              {!isCountryValid
                ? 'Please select a Valid Country'
                : ''}
            </FormHelperText>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <CustomSelect
              value={stateId}
              name="stateName"
              label="State"
              placeholder="Select State"
              options={allStates}
              handleSelectChange={handleStateChange}
              onChange={onChange}
              inputValue={stateName}
              error={!isStateValid}
              onBlur={checkStateValidity}
            />
            <FormHelperText className={classes.Error_msg}>
              {!isStateValid ? 'Please select a Valid State' : ''}
            </FormHelperText>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <CustomSelect
              value={cityId}
              name="cityName"
              label="City"
              placeholder="Select City"
              options={allCity}
              handleSelectChange={handleCityChange}
              onChange={onChange}
              inputValue={cityName}
              error={!isCityValid}
              onBlur={checkCityValidity}
            />
            <FormHelperText className={classes.Error_msg}>
              {!isCityValid ? 'Please select a Valid City' : ''}
            </FormHelperText>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <CustomTextField
              value={pinCode}
              name="pinCode"
              label="Pincode"
              placeholder="Enter Pincode"
              type="text"
              onChange={onChange}
              error={!isPinCodeValid}
              onBlur={checkPinCodeValidity}
            />
            <FormHelperText className={classes.Error_msg}>
              {!isPinCodeValid ? 'Invalid Pin Code' : ''}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <CustomTextField
              // value="hiii"
              label="Upload File"
              placeholder="Logo"
              type="text"
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CustomerDetails;
