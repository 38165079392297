/**
 * @module AddCustomer
 * @summary This module exports the main add customer component
 */
import React, { Component } from 'react';
import moment from 'moment';
import {
  Grid,
  Modal,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material';
import {
  ColorButton,
  ColorOutlinedButton
} from '../../hoc/PaletteComponent';
import './AddCustomer.css';
import CustomerDetails from './CustomerDetails';
import RolesAndFeatures from './RolesAndFeatures';
import BillingPlan from './BillingPlan';
import withSharedSnackbar from '../../hoc/Snackbar/withSharedSnackbar';
import withApollo from '../../client/withApollo';
import {
  GET_ALL_COUNTRIES,
  GET_CITIES,
  GET_STATES,
  GET_ROLES_FEATURES_CATEGORY,
  CUSTOMER_ONBOARD,
  GET_CUSTOMER_DETAILS_BY_OEM_ID,
  UPDATE_CUSTOMER_BY_OEMID
} from './queryCustomerPage';
import { parseCategoriesData } from './utils';
import { getLoginId, getOwnerId } from '../../utils';

/**
 * @summary Created customer context to pass all the state value to child component
 */
export const CustomerContext = React.createContext();

/**
 * @summary Create data function is used to create subfeatures from ${categoryRows}
 */
const createData = subFeatures => {
  return { subFeatures };
};

/**
 * @summary AddCustomer component opens the Customer dialog on clicking add customer  button
 * @param {object} props React component props
 */
class AddCustomer extends Component {
  state = {
    isEdit: false,
    isTallyCustomerNew: false,
    isEditOfExistingCustomer: false,
    stepCount: 0,
    steps: ['', '', ''],
    customerName: '',
    userName: '',
    password: '',
    oemCode: '',
    emailId: '',
    address1: '',
    address2: '',
    phoneNumber: '',
    bankName: '',
    bankId: '',
    allBanks: [
      {
        value: '1',
        label: 'Canara'
      },
      {
        value: '2',
        label: 'SBI'
      },
      {
        value: '3',
        label: 'HDFC'
      }
    ],
    accountNumber: '',
    accountHolder: '',
    ifsc: '',
    gstNumber: '',
    panNumber: '',
    countryId: '',
    countryName: '',
    allCountries: null,
    stateId: '',
    stateName: '',
    allStates: null,
    cityName: '',
    cityId: '',
    allCity: null,
    pinCode: '',
    isCategorySelected: false,
    isAnyRolesSelected: false,
    categoryColumns: [
      {
        id: 'subFeatures',
        label: 'Sub-Features',
        minWidth: 200,
        align: 'center'
      },
      { id: 'read', label: 'Read', minWidth: 30, align: 'center' },
      {
        id: 'create',
        label: 'Create',
        minWidth: 30,
        align: 'center'
      },
      {
        id: 'edit',
        label: 'Edit',
        minWidth: 30,
        align: 'center'
      },
      {
        id: 'delete',
        label: 'Delete',
        minWidth: 30,
        align: 'center',
        format: value => value.toFixed(2)
      }
    ],
    requiredSubCategoriesForTable: [],
    selectedCategoryIndex: null,
    categoryDataForBillingPage: [],
    billingPageInitialState: true,
    allCategoriesData: [],
    nestedListOpen: true,
    selectedPlan: '',
    selectedSubPlan: '',

    planName: '',
    approvedTcuName: '',
    assignedTcuCount: '',
    totalTcuCount: '',
    newTcuCount: '',
    expiryTime: '',
    warrantyTime: '',

    planStatusId: '',
    planStatusName: '',
    allPlanStatus: [
      {
        value: '1',
        label: 'ACTIVE'
      },
      {
        value: '2',
        label: 'INACTIVE'
      },
      {
        value: '3',
        label: 'APPROVAL PENDING'
      }
    ],
    planTypeId: '',
    planTypeName: '',
    allPlanTypes: [
      {
        value: '1',
        label: 'POC'
      },
      {
        value: '2',
        label: 'COMMERCIAL'
      }
    ],
    planData: [{ id: 1, planName: 'Plan' }],
    allCurrency: [
      {
        value: '1',
        label: 'INR'
      },
      {
        value: '2',
        label: 'Dollar'
      },
      {
        value: '3',
        label: 'Yen'
      }
    ],
    allTcuBillingModes: [
      {
        value: '1',
        label: 'PREPAID'
      },
      {
        value: '2',
        label: 'POSTPAID'
      }
    ],
    allSimBillingModes: [
      {
        value: '1',
        label: 'PREPAID'
      },
      {
        value: '2',
        label: 'POSTPAID'
      }
    ],
    allTcuBillingPeriods: [
      {
        value: '30',
        label: 'Monthly'
      },
      {
        value: '90',
        label: 'Quarterly'
      },
      {
        value: '180',
        label: 'Half-Yearly'
      },
      {
        value: '365',
        label: 'Yearly'
      }
    ],
    allSimBillingPeriods: [
      {
        value: '30',
        label: 'Monthly'
      },
      {
        value: '90',
        label: 'Quarterly'
      },
      {
        value: '180',
        label: 'Half-Yearly'
      },
      {
        value: '365',
        label: 'Yearly'
      }
    ],
    allTcuTaxPercents: [
      {
        value: '1',
        label: '8 %'
      },
      {
        value: '2',
        label: '18 %'
      },
      {
        value: '3',
        label: '28 %'
      }
    ],
    allSimTaxPercents: [
      {
        value: '1',
        label: '8 %'
      },
      {
        value: '2',
        label: '18 %'
      },
      {
        value: '3',
        label: '28 %'
      }
    ],
    selectedCategory: '',
    selectedAccordian: '',
    isCustomerNameValid: true,
    isCustomerEmailValid: true,
    isPhoneNumberValid: true,
    isAccountNumberValid: true,
    isBankNameValid: true,
    isAccountHolderValid: true,
    isIfscValid: true,
    isGstValid: true,
    isPanNumberValid: true,
    isCountryValid: true,
    isStateValid: true,
    isCityValid: true,
    isPinCodeValid: true,

    isPlanNameValid: true,
    isNewTcuCountValid: true,
    isPlanStatusSelected: true,
    isPlanTypeSelected: true,
    isExpiryTimeValid: true,
    isWarrantyTimeValid: true,
    isApprovedTcuNameSelected: true,
    isAssignedTcuCountSelected: true,
    isTotalTcuCountSelected: true,

    isTcuBilingModeValid: true,
    isSimBilingModeValid: true,
    isTcuPacketValid: true,
    isSimPacketValid: true,
    isTcuTrackValid: true,
    isSimTrackValid: true,
    isTcuBillingPeriodValid: true,
    isSimBillingPeriodValid: true,
    isTcuTaxPercentValid: true,
    isSimTaxPercentValid: true,
    isTcuPeriodChargeValid: true,
    isSimPeriodChargeValid: true,
    isTcuFreeDaysValid: true,
    isSimFreeDaysValid: true,
    isTcuExpiryPeriodValid: true,
    isSimExpiryPeriodValid: true,
    isTcuCreditDaysValid: true,
    isSimCreditDaysValid: true
  };

  componentDidMount = async () => {
    await this.fetchDataForEditCustomer();
    await this.getAllCountries();
    await this.getRolesFeaturesCategories();
  };

  /**
   * @function
   * @summary This function fetch all the countries
   */
  getAllCountries = async () => {
    try {
      const countryResponse = await this.props.client.query({
        query: GET_ALL_COUNTRIES
      });
      let array = [];
      if (countryResponse) {
        countryResponse.data.getAllCountries.map(country => {
          array.push({
            value: country.id,
            label: country.name
          });
        });
        this.setState({ allCountries: array });
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  /**
   * @function
   * @summary This function fetch all the states based on country id
   */
  getStates = async countryId => {
    try {
      const stateResponse = await this.props.client.query({
        query: GET_STATES,
        variables: { countryId }
      });
      let array = [];
      if (stateResponse) {
        stateResponse.data.getStateByCountryId.map(state => {
          array.push({
            value: state.id,
            label: state.name
          });
        });
        this.setState({ allStates: array });
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  /**
   * @function
   * @summary This function fetch all the city based on state id
   */
  getCity = async stateId => {
    try {
      const cityResponse = await this.props.client.query({
        query: GET_CITIES,
        variables: { stateId }
      });
      let array = [];
      if (cityResponse) {
        cityResponse.data.getCityByStateId.map(city => {
          array.push({
            value: city.id,
            label: city.name
          });
        });
        this.setState({ allCity: array });
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };
  /*
   * @summary This function fetches all Categories of Roles and Features Page
   */
  getRolesFeaturesCategories = async () => {
    let dataArray = [];
    try {
      const response = await this.props.client.query({
        query: GET_ROLES_FEATURES_CATEGORY,
        errorPolicy: 'all'
      });

      dataArray.push(response.data.getCategories);

      const newCategoriesResponse = await parseCategoriesData(
        dataArray
      );
      if (this.state.isEdit == false) {
        this.setState({ allCategoriesData: newCategoriesResponse });
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  /**
   * @function
   * @summary This function fetch data of Customer being edited and setting required states
   */
  fetchDataForEditCustomer = async () => {
    if (this.props.oemId != null) {
      if (this.props.tallyCustomerStatus == null) {
        this.setState({
          isTallyCustomerNew: true,
          isEditOfExistingCustomer: false
        });
      }

      try {
        const response = await this.props.client.query({
          query: GET_CUSTOMER_DETAILS_BY_OEM_ID,
          variables: {
            oemId: this.props.oemId
          },
          errorPolicy: 'all'
        });
        let fetchedData = response.data.getCustomerDetailsByOemId;
        let tempData = fetchedData.allCategoriesData;

        // console.log(
        //   'fetchedData.allCategoriesData:',
        //   fetchedData.allCategoriesData
        // );

        let tempCategoryArray = [];

        for (let i in tempData) {
          let tempSubCategoryArray = [];
          for (let j in tempData[i].subCategories) {
            let tempSubObj = {
              name: tempData[i].subCategories[j].name,
              featureId: tempData[i].subCategories[j].featureId,
              read: tempData[i].subCategories[j].read,
              create: tempData[i].subCategories[j].create,
              edit: tempData[i].subCategories[j].edit,
              delete: tempData[i].subCategories[j].delete
            };
            tempSubCategoryArray.push(tempSubObj);
          }

          let tempCatObj = {
            name: tempData[i].name,
            categoryId: tempData[i].categoryId,
            subCategories: tempSubCategoryArray,
            isPlanRecurring: tempData[i].isPlanRecurring,
            isBillingTcuWise: tempData[i].isBillingTcuWise,
            currencyId: tempData[i].currencyId,
            currencyName: tempData[i].currencyName,
            tcuBillingId: tempData[i].tcuBillingId,
            tcuBillingMode: tempData[i].tcuBillingMode,
            simBillingId: tempData[i].simBillingId,
            simBillingMode: tempData[i].simBillingMode,
            tcuPacket: tempData[i].tcuPacket,
            simPacket: tempData[i].simPacket,
            tcuTrack: tempData[i].tcuTrack,
            simTrack: tempData[i].simTrack,
            tcuBillingPeriodId: tempData[i].tcuBillingPeriodId,
            tcuBillingPeriod: tempData[i].tcuBillingPeriod,
            simBillingPeriodId: tempData[i].simBillingPeriodId,
            simBillingPeriod: tempData[i].simBillingPeriod,
            tcuTaxPercentId: tempData[i].tcuTaxPercentId,
            tcuTaxPercent: tempData[i].tcuTaxPercent,
            simTaxPercentId: tempData[i].simTaxPercentId,
            simTaxPercent: tempData[i].simTaxPercent,
            tcuPeriodCharge: tempData[i].tcuPeriodCharge,
            simPeriodCharge: tempData[i].simPeriodCharge,
            tcuFreeDays: tempData[i].tcuFreeDays,
            simFreeDays: tempData[i].simFreeDays,
            tcuExpiryPeriod: tempData[i].tcuExpiryPeriod,
            simExpiryPeriod: tempData[i].simExpiryPeriod,
            tcuCreditDays: tempData[i].tcuCreditDays,
            simCreditDays: tempData[i].simCreditDays,
            isTcuBilingModeValid: true,
            isSimBilingModeValid: true,
            isTcuPacketValid: true,
            isSimPacketValid: true,
            isTcuTrackValid: true,
            isSimTrackValid: true,
            isTcuBillingPeriodValid: true,
            isSimBillingPeriodValid: true,
            isTcuTaxPercentValid: true,
            isSimTaxPercentValid: true,
            isTcuPeriodChargeValid: true,
            isSimPeriodChargeValid: true,
            isTcuFreeDaysValid: true,
            isSimFreeDaysValid: true,
            isTcuExpiryPeriodValid: true,
            isSimExpiryPeriodValid: true,
            isTcuCreditDaysValid: true,
            isSimCreditDaysValid: true
          };

          tempCategoryArray.push(tempCatObj);
        }

        // console.log('tempCategoryArray:', tempCategoryArray);

        this.setState({
          isEdit: true,
          isEditOfExistingCustomer:
            this.state.isTallyCustomerNew == true ? false : true,
          isCategorySelected: true,
          isAnyRolesSelected: true,
          customerName: fetchedData.customerName,
          accountHolder: fetchedData.accountHolder,
          accountNumber: fetchedData.accountNumber,
          address1: fetchedData.address1,
          address2: fetchedData.address2,
          bankName:
            fetchedData.bankName == null ? '' : fetchedData.bankName,
          // billingStatus: 'ACTIVE',
          cityName: fetchedData.cityName,
          gstNumber: fetchedData.gstNumber,
          ifsc: fetchedData.ifsc,
          oemCode: fetchedData.oemCode,
          emailId: fetchedData.emailId,
          // oemName: 'macprFiftyOne',
          // ownerId: 1,
          panNumber: fetchedData.panNumber,
          password: fetchedData.password,
          phoneNumber: fetchedData.phoneNumber,
          pinCode: fetchedData.pinCode,
          // planExpiry: '2',

          // planType: 'POC',
          stateName: fetchedData.stateName,
          countryName: fetchedData.countryName,
          countryId: fetchedData.countryId,
          stateId: fetchedData.stateId,
          cityId: fetchedData.city,

          userName: fetchedData.userName,

          planName: fetchedData.planName,
          totalTcuCount: fetchedData.totalTcuCount,
          expiryTime: fetchedData.expiryTime,
          warrantyTime: fetchedData.warrantyTime,
          planTypeName:
            fetchedData.planTypeName == null
              ? ''
              : fetchedData.planTypeName,
          planStatusName:
            fetchedData.planStatusName == null
              ? ''
              : fetchedData.planStatusName,
          allCategoriesData: tempCategoryArray
        });
      } catch (err) {
        console.log('Error while fetching Customer data:', err);
      }
    }
  };

  /**
   * @function
   * @summary This function handle the stepper to increment the steps
   */
  handleNext = async () => {
    let activeStep;
    if (this.state.stepCount == 0) {
      activeStep = this.state.stepCount + 1;
      if (this.state.isEdit == true) {
        if (this.state.isTallyCustomerNew == true) {
          // console.log("inside If Part")
          this.setState({
            allCategoriesData: [
              {
                name: 'Device Lifecycle Management',
                categoryId: 1,
                subCategories: [
                  {
                    name: 'Analytics',
                    featureId: 1,
                    read: false,
                    create: false,
                    edit: false,
                    delete: false
                  },
                  {
                    name: 'DeviceManager',
                    featureId: 2,
                    read: false,
                    create: false,
                    edit: false,
                    delete: false
                  },
                  {
                    name: 'Device-Group-Manager',
                    featureId: 3,
                    read: false,
                    create: false,
                    edit: false,
                    delete: false
                  },
                  {
                    name: 'Sim-Management',
                    featureId: 4,
                    read: false,
                    create: false,
                    edit: false,
                    delete: false
                  },
                  {
                    name: 'OTA-Campaign',
                    featureId: 5,
                    read: false,
                    create: false,
                    edit: false,
                    delete: false
                  },
                  {
                    name: 'Data Push',
                    featureId: 6,
                    read: false,
                    create: false,
                    edit: false,
                    delete: false
                  }
                ],
                isPlanRecurring: false,
                isBillingTcuWise: false,
                currencyId: '',
                currencyName: '',
                tcuBillingId: '',
                tcuBillingMode: '',
                simBillingId: '',
                simBillingMode: '',
                tcuPacket: '',
                simPacket: '',
                tcuTrack: '',
                simTrack: '',
                tcuBillingPeriodId: '',
                tcuBillingPeriod: '',
                simBillingPeriodId: '',
                simBillingPeriod: '',
                tcuTaxPercentId: '',
                tcuTaxPercent: '',
                simTaxPercentId: '',
                simTaxPercent: '',
                tcuPeriodCharge: '',
                simPeriodCharge: '',
                tcuFreeDays: '',
                simFreeDays: '',
                tcuExpiryPeriod: '',
                simExpiryPeriod: '',
                tcuCreditDays: '',
                simCreditDays: '',
                isTcuBilingModeValid: true,
                isSimBilingModeValid: true,
                isTcuPacketValid: true,
                isSimPacketValid: true,
                isTcuTrackValid: true,
                isSimTrackValid: true,
                isTcuBillingPeriodValid: true,
                isSimBillingPeriodValid: true,
                isTcuTaxPercentValid: true,
                isSimTaxPercentValid: true,
                isTcuPeriodChargeValid: true,
                isSimPeriodChargeValid: true,
                isTcuFreeDaysValid: true,
                isSimFreeDaysValid: true,
                isTcuExpiryPeriodValid: true,
                isSimExpiryPeriodValid: true,
                isTcuCreditDaysValid: true,
                isSimCreditDaysValid: true
              },
              {
                name: 'Certification Manager',
                categoryId: 2,
                subCategories: [
                  {
                    name: 'Certification Manager',
                    featureId: 7,
                    read: false,
                    create: false,
                    edit: false,
                    delete: false
                  },
                  {
                    name: 'Certification Viewer',
                    featureId: 8,
                    read: false,
                    create: false,
                    edit: false,
                    delete: false
                  },
                  {
                    name: 'Analytics',
                    featureId: 9,
                    read: false,
                    create: false,
                    edit: false,
                    delete: false
                  }
                ],
                isPlanRecurring: false,
                isBillingTcuWise: false,
                currencyId: '',
                currencyName: '',
                tcuBillingId: '',
                tcuBillingMode: '',
                simBillingId: '',
                simBillingMode: '',
                tcuPacket: '',
                simPacket: '',
                tcuTrack: '',
                simTrack: '',
                tcuBillingPeriodId: '',
                tcuBillingPeriod: '',
                simBillingPeriodId: '',
                simBillingPeriod: '',
                tcuTaxPercentId: '',
                tcuTaxPercent: '',
                simTaxPercentId: '',
                simTaxPercent: '',
                tcuPeriodCharge: '',
                simPeriodCharge: '',
                tcuFreeDays: '',
                simFreeDays: '',
                tcuExpiryPeriod: '',
                simExpiryPeriod: '',
                tcuCreditDays: '',
                simCreditDays: '',
                isTcuBilingModeValid: true,
                isSimBilingModeValid: true,
                isTcuPacketValid: true,
                isSimPacketValid: true,
                isTcuTrackValid: true,
                isSimTrackValid: true,
                isTcuBillingPeriodValid: true,
                isSimBillingPeriodValid: true,
                isTcuTaxPercentValid: true,
                isSimTaxPercentValid: true,
                isTcuPeriodChargeValid: true,
                isSimPeriodChargeValid: true,
                isTcuFreeDaysValid: true,
                isSimFreeDaysValid: true,
                isTcuExpiryPeriodValid: true,
                isSimExpiryPeriodValid: true,
                isTcuCreditDaysValid: true,
                isSimCreditDaysValid: true
              }
            ],
            isCategorySelected: false
          });
        } else {
          // console.log("inside Else Part")
          this.setState({
            isCategorySelected: true,
            selectedCategory: this.state.allCategoriesData[0].name,
            selectedCategoryIndex: 1,
            requiredSubCategoriesForTable:
              this.state.allCategoriesData[0].subCategories,
            categoryDataForBillingPage: this.state.allCategoriesData
          });
        }
      }

      this.setState({
        stepCount: activeStep
      });
    } else if (this.state.stepCount == 1) {
      activeStep = this.state.stepCount + 1;
      this.setState({
        stepCount: activeStep,
        selectedPlan: '',
        selectedSubPlan: ''
      });

      let newData;
      newData = this.state.allCategoriesData;
      let foundCurrencyCheckArray = await newData.map(eachData => {
        if (
          eachData.simBillingId == '' ||
          eachData.simBillingPeriodId == '' ||
          eachData.simCreditDays == '' ||
          eachData.simExpiryPeriod == '' ||
          eachData.simFreeDays == '' ||
          eachData.simPacket == '' ||
          eachData.simPeriodCharge == '' ||
          eachData.simTaxPercentId == '' ||
          eachData.simTrack == '' ||
          eachData.tcuBillingId == '' ||
          eachData.tcuBillingPeriodId == '' ||
          eachData.tcuCreditDays == '' ||
          eachData.tcuExpiryPeriod == '' ||
          eachData.tcuFreeDays == '' ||
          eachData.tcuPacket == '' ||
          eachData.tcuPeriodCharge == '' ||
          eachData.tcuTaxPercentId == '' ||
          eachData.tcuTrack == ''
        ) {
          this.setState({
            billingPageInitialState: false
          });
        }
      });
    }
  };

  /**
   * @function
   * @summary This function handle the stepper to decrement the steps
   */
  handleBack = () => {
    if (this.state.stepCount > 0) {
      let activeStep;
      activeStep = this.state.stepCount - 1;
      this.setState({ stepCount: activeStep });
    } else {
      this.props.openSnackbar('Please go on next page');
    }
  };

  /**
   * @function
   * @summary Generic input change handler
   */
  handleInputChange = e => {
    if (e.target.name == 'tcuPacket') {
      let newData;
      newData = this.state.categoryDataForBillingPage;
      newData.filter(
        eachObject => eachObject.name == this.state.selectedAccordian
      )[0].tcuPacket = e.target.value;
      this.setState({
        categoryDataForBillingPage: newData
      });
    } else if (e.target.name == 'simPacket') {
      let newData;
      newData = this.state.categoryDataForBillingPage;
      newData.filter(
        eachObject => eachObject.name == this.state.selectedAccordian
      )[0].simPacket = e.target.value;
      this.setState({
        categoryDataForBillingPage: newData
      });
    } else if (e.target.name == 'tcuTrack') {
      let newData;
      newData = this.state.categoryDataForBillingPage;
      newData.filter(
        eachObject => eachObject.name == this.state.selectedAccordian
      )[0].tcuTrack = e.target.value;
      this.setState({
        categoryDataForBillingPage: newData
      });
    } else if (e.target.name == 'simTrack') {
      let newData;
      newData = this.state.categoryDataForBillingPage;
      newData.filter(
        eachObject => eachObject.name == this.state.selectedAccordian
      )[0].simTrack = e.target.value;
      this.setState({
        categoryDataForBillingPage: newData
      });
    } else if (e.target.name == 'tcuPeriodCharge') {
      let newData;
      newData = this.state.categoryDataForBillingPage;
      newData.filter(
        eachObject => eachObject.name == this.state.selectedAccordian
      )[0].tcuPeriodCharge = e.target.value;
      this.setState({
        categoryDataForBillingPage: newData
      });
    } else if (e.target.name == 'simPeriodCharge') {
      let newData;
      newData = this.state.categoryDataForBillingPage;
      newData.filter(
        eachObject => eachObject.name == this.state.selectedAccordian
      )[0].simPeriodCharge = e.target.value;
      this.setState({
        categoryDataForBillingPage: newData
      });
    } else if (e.target.name == 'tcuFreeDays') {
      let newData;
      newData = this.state.categoryDataForBillingPage;
      newData.filter(
        eachObject => eachObject.name == this.state.selectedAccordian
      )[0].tcuFreeDays = e.target.value;
      this.setState({
        categoryDataForBillingPage: newData
      });
    } else if (e.target.name == 'simFreeDays') {
      let newData;
      newData = this.state.categoryDataForBillingPage;
      newData.filter(
        eachObject => eachObject.name == this.state.selectedAccordian
      )[0].simFreeDays = e.target.value;
      this.setState({
        categoryDataForBillingPage: newData
      });
    } else if (e.target.name == 'tcuExpiryPeriod') {
      let newData;
      newData = this.state.categoryDataForBillingPage;
      newData.filter(
        eachObject => eachObject.name == this.state.selectedAccordian
      )[0].tcuExpiryPeriod = e.target.value;
      this.setState({
        categoryDataForBillingPage: newData
      });
    } else if (e.target.name == 'simExpiryPeriod') {
      let newData;
      newData = this.state.categoryDataForBillingPage;
      newData.filter(
        eachObject => eachObject.name == this.state.selectedAccordian
      )[0].simExpiryPeriod = e.target.value;
      this.setState({
        categoryDataForBillingPage: newData
      });
    } else if (e.target.name == 'tcuCreditDays') {
      let newData;
      newData = this.state.categoryDataForBillingPage;
      newData.filter(
        eachObject => eachObject.name == this.state.selectedAccordian
      )[0].tcuCreditDays = e.target.value;
      this.setState({
        categoryDataForBillingPage: newData
      });
    } else if (e.target.name == 'simCreditDays') {
      let newData;
      newData = this.state.categoryDataForBillingPage;
      newData.filter(
        eachObject => eachObject.name == this.state.selectedAccordian
      )[0].simCreditDays = e.target.value;
      this.setState({
        categoryDataForBillingPage: newData
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value
      });
    }
  };

  /**
   * @function
   * @summary This function handle the category change in roles and feature component
   */
  handleCategoryClick = async (value, index) => {
    this.setState({ requiredSubCategoriesForTable: [] });

    const requiredData = await this.state.allCategoriesData.filter(
      oneObject => {
        if (oneObject.name == value) {
          return oneObject;
        }
      }
    );

    this.setState({
      isCategorySelected: true,
      selectedCategory: value,
      selectedCategoryIndex: index,
      requiredSubCategoriesForTable: requiredData[0].subCategories
    });
  };

  /**
   * @summary This function handle the Plan sub-field's nested list collapse and de-collapse in Billing Plan component
   */

  handleClick = planName => {
    if (planName != this.state.selectedPlan) {
      this.setState({
        nestedListOpen: true,
        selectedPlan: planName,
        selectedSubPlan: ''
      });
    } else {
      this.setState({
        nestedListOpen: !this.state.nestedListOpen,
        selectedPlan: planName,
        selectedSubPlan: ''
      });
    }
  };

  /**
   * @summary This function handle the Plan's (Plan Info) select in Billing Plan component
   */

  handlePlanInfo = () => {
    this.setState({ selectedSubPlan: 'Plan Info' });
  };

  /**
   * @summary This function get Unique Objects in newData array of handleCategories function
   */
  getUniqueObjects = (array, name) => {
    return [
      ...new Map(array.map(item => [item[name], item])).values()
    ];
  };

  /**
   * @summary This function handle the Plan's (Categories) select in Billing Plan component
   */
  handleCategories = async () => {
    this.setState({ selectedSubPlan: 'Categories' });
    let newData = [];
    let data = this.state.allCategoriesData;

    newData = await data.map(eachData => {
      for (let i = 0; i < eachData.subCategories.length; i++) {
        if (
          eachData.subCategories[i].read == true ||
          eachData.subCategories[i].create == true ||
          eachData.subCategories[i].edit == true ||
          eachData.subCategories[i].delete == true
        ) {
          newData.push(eachData);
        }
      }

      const uniqueArrayByCategoryName = this.getUniqueObjects(
        newData,
        'name'
      );

      this.setState({
        categoryDataForBillingPage: uniqueArrayByCategoryName
      });
    });
  };

  /**
   * @summary This function handle the Plan State change in Billing Plan component
   */
  handlePlanStatusChange = (e, select) => {
    if (!select) {
      this.setState({ planStatusName: '', planStatusId: '' });
    } else {
      this.setState({
        planStatusName: select.label,
        planStatusId: select.value
      });
    }
  };

  /**
   * @summary This function handle the Plan Type change in Billing Plan component
   */
  handlePlanTypeChange = (e, select) => {
    if (!select) {
      this.setState({ planTypeName: '', planTypeId: '' });
    } else {
      this.setState({
        planTypeName: select.label,
        planTypeId: select.value
      });
    }
  };
  /**
   * @summary This function handle the Expiry Time change in Billing Plan component
   */
  handleExpiryTimeChange = date => {
    this.setState({ expiryTime: date });
  };

  /**
   * @summary This function handle the Warranty Time change in Billing Plan component
   */
  handleWarrantyTimeChange = date => {
    this.setState({ warrantyTime: date });
  };

  /**
   * @summary This function handle the Currency State change in Billing Plan Categories component
   */
  handleCurrencyChange = (event, select) => {
    if (!select) {
      let newData;
      newData = this.state.categoryDataForBillingPage;

      newData.filter(eachObject => {
        if (eachObject.name == this.state.selectedAccordian) {
          eachObject.currencyId = '';
          eachObject.currencyName = '';
        }
      });

      this.setState({
        categoryDataForBillingPage: newData
      });
    } else {
      let newData;
      newData = this.state.categoryDataForBillingPage;

      newData.filter(eachObject => {
        if (eachObject.name == this.state.selectedAccordian) {
          eachObject.currencyId = select.label;
          eachObject.currencyName = select.value;
        }
      });

      this.setState({
        categoryDataForBillingPage: newData
      });
    }
  };

  /**
   * @summary This function handle the TCU Billing Mode change in Billing Plan Categories component
   */
  handleTcuBillingChange = (event, select) => {
    if (!select) {
      let newData;
      newData = this.state.categoryDataForBillingPage;

      newData.filter(eachObject => {
        if (eachObject.name == this.state.selectedAccordian) {
          eachObject.tcuBillingId = '';
          eachObject.tcuBillingMode = '';
          eachObject.isTcuBilingModeValid = false;
        }
      });

      this.setState({
        categoryDataForBillingPage: newData
      });
    } else {
      let newData;
      newData = this.state.categoryDataForBillingPage;

      newData.filter(eachObject => {
        if (eachObject.name == this.state.selectedAccordian) {
          eachObject.tcuBillingId = select.label;
          eachObject.tcuBillingMode = select.value;
          eachObject.isTcuBilingModeValid = true;
        }
      });

      this.setState({
        categoryDataForBillingPage: newData
      });
    }
  };

  /**
   * @summary This function handle the SIM Billing Mode change in Billing Plan Categories component
   */
  handleSimBillingModeChange = (event, select) => {
    if (!select) {
      let newData;
      newData = this.state.categoryDataForBillingPage;

      newData.filter(eachObject => {
        if (eachObject.name == this.state.selectedAccordian) {
          eachObject.simBillingId = '';
          eachObject.simBillingMode = '';
          eachObject.isSimBilingModeValid = false;
        }
      });

      this.setState({
        categoryDataForBillingPage: newData
      });
    } else {
      let newData;
      newData = this.state.categoryDataForBillingPage;

      newData.filter(eachObject => {
        if (eachObject.name == this.state.selectedAccordian) {
          eachObject.simBillingId = select.label;
          eachObject.simBillingMode = select.value;
          eachObject.isSimBilingModeValid = true;
        }
      });

      this.setState({
        categoryDataForBillingPage: newData
      });
    }
  };

  /**
   * @summary This function handle the TCU Billing Period change in Billing Plan Categories component
   */
  handleTcuBillingPeriodChange = (event, select) => {
    if (!select) {
      let newData;
      newData = this.state.categoryDataForBillingPage;

      newData.filter(eachObject => {
        if (eachObject.name == this.state.selectedAccordian) {
          eachObject.tcuBillingPeriodId = '';
          eachObject.tcuBillingPeriod = '';
          eachObject.isTcuBillingPeriodValid = false;
        }
      });

      this.setState({
        categoryDataForBillingPage: newData
      });
    } else {
      let newData;
      newData = this.state.categoryDataForBillingPage;

      newData.filter(eachObject => {
        if (eachObject.name == this.state.selectedAccordian) {
          eachObject.tcuBillingPeriodId = select.label;
          eachObject.tcuBillingPeriod = select.value;
          eachObject.isTcuBillingPeriodValid = true;
        }
      });
      this.setState({
        categoryDataForBillingPage: newData
      });
    }
  };

  /**
   * @summary This function handle the Sim Billing Period change in Billing Plan Categories component
   */
  handleSimBillingPeriodChange = (event, select) => {
    if (!select) {
      let newData;
      newData = this.state.categoryDataForBillingPage;

      newData.filter(eachObject => {
        if (eachObject.name == this.state.selectedAccordian) {
          eachObject.simBillingPeriodId = '';
          eachObject.simBillingPeriod = '';
          eachObject.isSimBillingPeriodValid = false;
        }
      });

      this.setState({
        categoryDataForBillingPage: newData
      });
    } else {
      let newData;
      newData = this.state.categoryDataForBillingPage;

      newData.filter(eachObject => {
        if (eachObject.name == this.state.selectedAccordian) {
          eachObject.simBillingPeriodId = select.label;
          eachObject.simBillingPeriod = select.value;
          eachObject.isSimBillingPeriodValid = true;
        }
      });
      this.setState({
        categoryDataForBillingPage: newData
      });
    }
  };

  /**
   * @summary This function handle the TCU Percent change in Billing Plan Categories component
   */
  handleTcuPercentChange = (event, select) => {
    if (!select) {
      let newData;
      newData = this.state.categoryDataForBillingPage;

      newData.filter(eachObject => {
        if (eachObject.name == this.state.selectedAccordian) {
          eachObject.tcuTaxPercentId = '';
          eachObject.tcuTaxPercent = '';
          eachObject.isTcuTaxPercentValid = false;
        }
      });

      this.setState({
        categoryDataForBillingPage: newData
      });
    } else {
      let newData;
      newData = this.state.categoryDataForBillingPage;

      newData.filter(eachObject => {
        if (eachObject.name == this.state.selectedAccordian) {
          eachObject.tcuTaxPercentId = select.label;
          eachObject.tcuTaxPercent = select.value;
          eachObject.isTcuTaxPercentValid = true;
        }
      });
      this.setState({
        categoryDataForBillingPage: newData
      });
    }
  };

  /**
   * @summary This function handle the Sim Percent change in Billing Plan Categories component
   */
  handleSimPercentChange = (event, select) => {
    if (!select) {
      let newData;
      newData = this.state.categoryDataForBillingPage;

      newData.filter(eachObject => {
        if (eachObject.name == this.state.selectedAccordian) {
          eachObject.simTaxPercentId = '';
          eachObject.simTaxPercent = '';
          eachObject.isSimTaxPercentValid = false;
        }
      });

      this.setState({
        categoryDataForBillingPage: newData
      });
    } else {
      let newData;
      newData = this.state.categoryDataForBillingPage;

      newData.filter(eachObject => {
        if (eachObject.name == this.state.selectedAccordian) {
          eachObject.simTaxPercentId = select.label;
          eachObject.simTaxPercent = select.value;
          eachObject.isSimTaxPercentValid = true;
        }
      });
      this.setState({
        categoryDataForBillingPage: newData
      });
    }
  };

  /**
   * @summary This function handle the Plan Recurring Switch change in Billing Plan Categories component
   */
  handlePlanRecurringChange = () => {
    let newData;
    newData = this.state.categoryDataForBillingPage;
    newData.filter(
      eachObject => eachObject.name == this.state.selectedAccordian
    )[0].isPlanRecurring = !this.state.categoryDataForBillingPage.filter(
      eachObject => eachObject.name == this.state.selectedAccordian
    )[0].isPlanRecurring;

    this.setState({
      categoryDataForBillingPage: newData
    });
  };

  /**
   * @summary This function handle the TCU Billing Switch change in Billing Plan Categories component
   */
  handleTcuBillingWiseChange = () => {
    let newData;
    newData = this.state.categoryDataForBillingPage;
    newData.filter(
      eachObject => eachObject.name == this.state.selectedAccordian
    )[0].isBillingTcuWise = !this.state.categoryDataForBillingPage.filter(
      eachObject => eachObject.name == this.state.selectedAccordian
    )[0].isBillingTcuWise;

    this.setState({
      categoryDataForBillingPage: newData
    });
  };

  /**
   * @function
   * @summary This function handle the Country change in customer details component
   */
  handleCountryChange = async (e, country) => {
    if (!country) {
      this.setState({
        countryName: '',
        countryId: '',
        allStates: null,
        stateName: '',
        stateId: '',
        allCity: null,
        cityName: '',
        cityId: ''
      });
    } else {
      await this.getStates(country.value);
      this.setState({
        countryName: country.label,
        countryId: country.value
      });
    }
  };

  /**
   * @function
   * @summary This function handle the State change in customer details component
   */
  handleStateChange = (e, state) => {
    if (!state) {
      this.setState({
        stateName: '',
        stateId: '',
        allCity: null,
        cityName: '',
        cityId: ''
      });
    } else {
      this.getCity(state.value);
      this.setState({
        stateName: state.label,
        stateId: state.value
      });
    }
  };

  /**
   * @function
   * @summary This function handle the City change in customer details component
   */
  handleCityChange = async (e, city) => {
    if (!city) {
      this.setState({ cityName: '', cityId: '' });
    } else {
      this.setState({
        cityName: city.label,
        cityId: city.value
      });
    }
  };

  /**
   * @function
   * @summary This function handle the Bank change in customer details component
   */
  handleBankChange = (e, bank) => {
    if (!bank) {
      this.setState({ bankName: '', bankId: '' });
    } else {
      this.setState({
        bankName: bank.label,
        bankId: bank.value
      });
    }
  };

  handleSave = async () => {
    const {
      customerName,
      userName,
      password,
      oemCode,
      emailId,
      phoneNumber,
      address1,
      address2,
      bankName,
      accountHolder,
      accountNumber,
      ifsc,
      gstNumber,
      panNumber,
      countryId,
      stateId,
      cityId,
      pinCode,
      isEdit,
      isTallyCustomerNew
    } = this.state;

    // console.log(
    //   'oemName: ',
    //   this.state.customerName,
    //   'username: ',
    //   this.state.userName,
    //   'password: ',
    //   this.state.password,
    //   'oemCode: ',
    //   this.state.oemCode,
    //   'oemEmail: ',
    //   this.state.emailId,
    //   'phoneNumber: ',
    //   this.state.phoneNumber,
    //   'addressLine1: ',
    //   this.state.address1,
    //   'addressLine2: ',
    //   this.state.address2,
    //   // "ownerId:", getOwnerId(),
    //   'planName: ',
    //   this.state.planName,
    //   'planType: ',
    //   this.state.planTypeName,
    //   'planExpiry: ',
    //   this.state.expiryTime,
    //   'warrantyPeriod: ',
    //   parseInt(this.state.warrantyTime),
    //   'billingStatus: ',
    //   this.state.planStatusName,
    //   'bankName: ',
    //   this.state.bankName,
    //   'accHoldingPerson: ',
    //   this.state.accountHolder,
    //   'accNumber: ',
    //   this.state.accountNumber,
    //   'ifsc: ',
    //   this.state.ifsc,
    //   'gst: ',
    //   this.state.gstNumber,
    //   'pan: ',
    //   this.state.panNumber,
    //   'countryId: ',
    //   this.state.countryId,
    //   'stateId: ',
    //   this.state.stateId,
    //   'city: ',
    //   this.state.cityId,
    //   'pincode: ',
    //   parseInt(this.state.pinCode),
    //   // "kycStatus:", 'INACTIVE',
    //   'totalTcuCount: ',
    //   parseInt(this.state.totalTcuCount),
    //   'this.state.categoryDataForBillingPage --',
    //   this.state.categoryDataForBillingPage
    // );

    try {
      if (!isEdit) {
        // console.log('inside edit false');
        const customerOnboardResponse =
          await this.props.client.mutate({
            mutation: CUSTOMER_ONBOARD,
            variables: {
              oemName: customerName,
              username: userName,
              password: password,
              oemCode,
              oemEmail: emailId,
              phoneNumber,
              addressLine1: address1,
              addressLine2: address2,
              ownerId: getOwnerId(),
              planName: this.state.planName,
              planType: this.state.planTypeName,
              planExpiry: this.state.expiryTime,
              warrantyPeriod: parseInt(this.state.warrantyTime),
              billingStatus: this.state.planStatusName,
              bankName,
              accHoldingPerson: accountHolder,
              accNumber: accountNumber,
              ifsc: ifsc,
              gst: gstNumber,
              pan: panNumber,
              countryId: countryId,
              stateId: stateId,
              city: cityId,
              pincode: parseInt(pinCode),
              kycStatus: 'INACTIVE',
              totalTcuCount: parseInt(this.state.totalTcuCount),
              dataArray: this.state.categoryDataForBillingPage
            }
          });

        console.log(
          'customerOnboardResponse ======',
          customerOnboardResponse.data.customerOnboard.statusCode
        );

        if (
          customerOnboardResponse.data.customerOnboard.statusCode ==
          200
        ) {
          this.props.openSnackbar('Customer Added Successfully');
          this.props.onClose();
        } else {
          this.props.openSnackbar(
            'Failed to Add customer. Try Again !!'
          );
          this.props.onClose();
        }
      } else {
        // console.log('inside edit true');

        if (this.state.categoryDataForBillingPage.length) {
          let customerUpdateResponse;

          if (this.state.isEditOfExistingCustomer == true) {
            customerUpdateResponse = await this.props.client.mutate({
              mutation: UPDATE_CUSTOMER_BY_OEMID,
              variables: {
                isTallyCustomer: isTallyCustomerNew,
                oemId: this.props.oemId,
                oemName: customerName,
                username: userName,
                password: password,
                oemCode,
                oemEmail: emailId,
                phoneNumber,
                addressLine1: address1,
                addressLine2: address2,
                ownerId: getOwnerId(),
                planName: this.state.planName,
                planType: this.state.planTypeName,
                // planExpiry: this.state.expiryTime,
                // warrantyPeriod: parseInt(this.state.warrantyTime),
                // planExpiry: null,
                // warrantyPeriod: null,
                billingStatus: this.state.planStatusName,
                bankName: this.state.bankName,
                accHoldingPerson: accountHolder,
                accNumber: accountNumber,
                ifsc: ifsc,
                gst: gstNumber,
                pan: panNumber,
                countryId: countryId,
                stateId: stateId,
                city: cityId,
                pincode: parseInt(pinCode),
                logo: null,
                totalTCUCount: parseInt(this.state.totalTcuCount),
                dataArray: this.state.categoryDataForBillingPage
              }
            });
          } else {
            customerUpdateResponse = await this.props.client.mutate({
              mutation: UPDATE_CUSTOMER_BY_OEMID,
              variables: {
                isTallyCustomer: isTallyCustomerNew,
                oemId: this.props.oemId,
                oemName: customerName,
                username: userName,
                password: password,
                oemCode,
                oemEmail: emailId,
                phoneNumber,
                addressLine1: address1,
                addressLine2: address2,
                ownerId: getOwnerId(),
                planName: this.state.planName,
                planType: this.state.planTypeName,
                planExpiry: this.state.expiryTime,
                warrantyPeriod: parseInt(this.state.warrantyTime),
                billingStatus: this.state.planStatusName,
                bankName: this.state.bankName,
                accHoldingPerson: accountHolder,
                accNumber: accountNumber,
                ifsc: ifsc,
                gst: gstNumber,
                pan: panNumber,
                countryId: countryId,
                stateId: stateId,
                city: cityId,
                pincode: parseInt(pinCode),
                logo: null,
                kycStatus: 'INACTIVE',
                totalTCUCount: parseInt(this.state.totalTcuCount),
                dataArray: this.state.categoryDataForBillingPage
              }
            });
          }

          // console.log(
          //   'customerOnboardResponse:',
          //   customerUpdateResponse.data.updateCustomerByOemId.code
          // );

          if (
            customerUpdateResponse.data.updateCustomerByOemId.code ==
            200
          ) {
            this.props.openSnackbar('Customer Updated Successfully');
            this.props.onClose();
          } else {
            this.props.openSnackbar(
              'Failed to Update customer. Try Again !!'
            );
            this.props.onClose();
          }
        }
      }
    } catch (err) {
      console.log('Error in main try catch', err);
      this.props.openSnackbar(
        'Error while adding customer. Try Again !!'
      );
      this.props.onClose();
    }
  };

  /**
   * @function
   * @summary This function handle the CustomCheck in Roles & Features page
   * @summary Also it handle enable & disable of Next button, if no CustomCheck is selected
   */
  handleCheckBoxClick = async (columnName, rowName) => {
    let newData;

    newData = this.state.allCategoriesData;

    // logic for Checkbox (check & uncheck)
    newData[this.state.selectedCategoryIndex].subCategories.filter(
      eachObject => eachObject.name == rowName
    )[0][columnName] = !this.state.allCategoriesData[
      this.state.selectedCategoryIndex
    ].subCategories.filter(
      eachObject => eachObject.name == rowName
    )[0][columnName];

    this.setState({ allCategoriesData: newData });

    // logic for enabling & disabling NEXT button
    let foundCheckedArray = await newData.map(eachObject =>
      eachObject.subCategories.map(eachSubCatObject => {
        if (
          eachSubCatObject.read == true ||
          eachSubCatObject.create == true ||
          eachSubCatObject.edit == true ||
          eachSubCatObject.delete == true
        ) {
          return true;
        }
      })
    );

    for (let i in foundCheckedArray) {
      if (foundCheckedArray[i].includes(true)) {
        this.setState({ isAnyRolesSelected: true });
        return;
      } else {
        this.setState({ isAnyRolesSelected: false });
      }
    }
  };

  /**
   * @function
   * @summary This function handle Accordian change in Billing Categories Page
   */
  handleAccordianChange = accordianName => {
    this.setState({ selectedAccordian: accordianName });
  };

  // Validation Functions
  checkCustomerNameValidity = () => {
    const { customerName } = this.state;
    const regex = new RegExp(/^[a-zA-Z0-9 ]{2,50}$/);
    this.setState({
      isCustomerNameValid: regex.test(customerName)
    });
  };

  checkCustomerEmailValidity = () => {
    const regex = new RegExp(
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    this.setState({
      isCustomerEmailValid: regex.test(this.state.emailId)
    });
  };

  checkPhoneNoValidity = () => {
    const regex = new RegExp(
      // eslint-disable-next-line
      /^[\s()+-]*[1-9]([[0-9][\s()+-]*){7,15}$/
    );
    this.setState({
      isPhoneNumberValid: regex.test(this.state.phoneNumber)
    });
  };

  checkAccountNumberValidity = () => {
    const regex = new RegExp(
      // eslint-disable-next-line
      /^\d{9,18}$/
    );
    this.setState({
      isAccountNumberValid: regex.test(this.state.accountNumber)
    });
  };

  checkBankNameValidity = () => {
    this.setState({ isBankNameValid: this.state.bankName !== '' });
  };
  checkAccountHolderValidity = () => {
    const regex = new RegExp(/^[a-zA-Z ]{2,30}$/);
    this.setState({
      isAccountHolderValid: regex.test(this.state.accountHolder)
    });
  };

  checkIfscValidity = () => {
    const regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
    this.setState({ isIfscValid: regex.test(this.state.ifsc) });
  };

  checkGstValidity = () => {
    const regex = new RegExp(
      // eslint-disable-next-line
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
    );
    this.setState({ isGstValid: regex.test(this.state.gstNumber) });
  };

  checkPanNumberValidity = () => {
    const regex = new RegExp(/[A-Z]{5}[0-9]{4}[A-Z]{1}/);
    this.setState({
      isPanNumberValid: regex.test(this.state.panNumber)
    });
  };

  checkCountryValidity = () => {
    this.setState({ isCountryValid: this.state.countryName !== '' });
  };

  checkStateValidity = () => {
    this.setState({ isStateValid: this.state.stateName !== '' });
  };

  checkCityValidity = () => {
    this.setState({ isCityValid: this.state.cityName !== '' });
  };

  checkPinCodeValidity = () => {
    const regex = new RegExp(/^[1-9]{1}[0-9]{2}[0-9]{3}$/);
    this.setState({
      isPinCodeValid: regex.test(this.state.pinCode)
    });
  };

  checkPlanNameValidity = () => {
    const regex = new RegExp(/^[a-zA-Z ]{3,45}$/);
    this.setState({
      isPlanNameValid: regex.test(this.state.planName)
    });
  };

  checkNewTcuCountValidity = () => {
    this.setState({
      isNewTcuCountValid: this.state.newTcuCount !== ''
    });
  };

  checkPlanStatusValidity = () => {
    this.setState({
      isPlanStatusSelected: this.state.planStatusName !== ''
    });
  };

  checkPlanTypeValidity = () => {
    this.setState({
      isPlanTypeSelected: this.state.planTypeName !== ''
    });
  };

  checkExpiryTimeValidity = () => {
    this.setState({
      isExpiryTimeValid: this.state.expiryTime !== ''
    });
  };

  checkWarrantyTimeValidity = () => {
    this.setState({
      isWarrantyTimeValid: this.state.warrantyTime !== ''
    });
  };

  checkApprovedTcuNameValidity = () => {
    this.setState({
      isApprovedTcuNameSelected: this.state.approvedTcuName !== ''
    });
  };

  checkAssignedTcuCountValidity = () => {
    this.setState({
      isAssignedTcuCountSelected: this.state.assignedTcuCount !== ''
    });
  };

  checkTotalTcuCountValidity = () => {
    this.setState({
      isTotalTcuCountSelected: this.state.totalTcuCount !== ''
    });
  };

  checkTcuBilingModeValidity = async () => {
    let newData;
    newData = this.state.categoryDataForBillingPage;

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].name == this.state.selectedAccordian) {
        newData[i].isTcuBilingModeValid =
          newData[i].tcuBillingMode !== '';
      }
    }

    await this.checkAllFields();
  };

  checkSimBilingModeValidity = async () => {
    let newData;
    newData = this.state.categoryDataForBillingPage;

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].name == this.state.selectedAccordian) {
        newData[i].isSimBilingModeValid =
          newData[i].simBillingMode !== '';
      }
    }

    await this.checkAllFields();
  };

  checkTcuPacketValidity = async () => {
    let newData;
    newData = this.state.categoryDataForBillingPage;

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].name == this.state.selectedAccordian) {
        newData[i].isTcuPacketValid = newData[i].tcuPacket !== '';
      }
    }

    await this.checkAllFields();
  };

  checkSimPacketValidity = async () => {
    let newData;
    newData = this.state.categoryDataForBillingPage;

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].name == this.state.selectedAccordian) {
        newData[i].isSimPacketValid = newData[i].simPacket !== '';
      }
    }

    await this.checkAllFields();
  };

  checkTcuTrackValidity = async () => {
    let newData;
    newData = this.state.categoryDataForBillingPage;

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].name == this.state.selectedAccordian) {
        newData[i].isTcuTrackValid = newData[i].tcuTrack !== '';
      }
    }

    await this.checkAllFields();
  };

  checkSimTrackValidity = async () => {
    let newData;
    newData = this.state.categoryDataForBillingPage;

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].name == this.state.selectedAccordian) {
        newData[i].isSimTrackValid = newData[i].simTrack !== '';
      }
    }

    await this.checkAllFields();
  };

  checkTcuBillingPeriodValidity = async () => {
    let newData;
    newData = this.state.categoryDataForBillingPage;

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].name == this.state.selectedAccordian) {
        newData[i].isTcuBillingPeriodValid =
          newData[i].tcuBillingPeriod !== '';
      }
    }

    await this.checkAllFields();
  };

  checkSimBillingPeriodValidity = async () => {
    let newData;
    newData = this.state.categoryDataForBillingPage;

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].name == this.state.selectedAccordian) {
        newData[i].isSimBillingPeriodValid =
          newData[i].simBillingPeriod !== '';
      }
    }

    await this.checkAllFields();
  };

  checkTcuTaxPercentValidity = async () => {
    let newData;
    newData = this.state.categoryDataForBillingPage;

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].name == this.state.selectedAccordian) {
        newData[i].isTcuTaxPercentValid =
          newData[i].tcuTaxPercent !== '';
      }
    }

    await this.checkAllFields();
  };

  checkSimTaxPercentValidity = async () => {
    let newData;
    newData = this.state.categoryDataForBillingPage;

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].name == this.state.selectedAccordian) {
        newData[i].isSimTaxPercentValid =
          newData[i].simTaxPercent !== '';
      }
    }

    await this.checkAllFields();
  };

  checkTcuPeriodChargeValidity = async () => {
    let newData;
    newData = this.state.categoryDataForBillingPage;

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].name == this.state.selectedAccordian) {
        newData[i].isTcuPeriodChargeValid =
          newData[i].tcuPeriodCharge !== '';
      }
    }

    await this.checkAllFields();
  };

  checkSimPeriodChargeValidity = async () => {
    let newData;
    newData = this.state.categoryDataForBillingPage;

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].name == this.state.selectedAccordian) {
        newData[i].isSimPeriodChargeValid =
          newData[i].simPeriodCharge !== '';
      }
    }

    await this.checkAllFields();
  };

  checkTcuFreeDaysValidity = async () => {
    let newData;
    newData = this.state.categoryDataForBillingPage;

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].name == this.state.selectedAccordian) {
        newData[i].isTcuFreeDaysValid =
          newData[i].tcuFreeDays !== '';
      }
    }

    await this.checkAllFields();
  };

  checkSimFreeDaysValidity = async () => {
    let newData;
    newData = this.state.categoryDataForBillingPage;

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].name == this.state.selectedAccordian) {
        newData[i].isSimFreeDaysValid =
          newData[i].simFreeDays !== '';
      }
    }

    await this.checkAllFields();
  };

  checkTcuExpiryPeriodValidity = async () => {
    let newData;
    newData = this.state.categoryDataForBillingPage;

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].name == this.state.selectedAccordian) {
        newData[i].isTcuExpiryPeriodValid =
          newData[i].tcuExpiryPeriod !== '';
      }
    }

    await this.checkAllFields();
  };

  checkSimExpiryPeriodValidity = async () => {
    let newData;
    newData = this.state.categoryDataForBillingPage;

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].name == this.state.selectedAccordian) {
        newData[i].isSimExpiryPeriodValid =
          newData[i].simExpiryPeriod !== '';
      }
    }

    await this.checkAllFields();
  };

  checkTcuCreditDaysValidity = async () => {
    let newData;
    newData = this.state.categoryDataForBillingPage;

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].name == this.state.selectedAccordian) {
        newData[i].isTcuCreditDaysValid =
          newData[i].tcuCreditDays !== '';
      }
    }

    await this.checkAllFields();
  };

  checkSimCreditDaysValidity = async () => {
    let newData;
    newData = this.state.categoryDataForBillingPage;

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].name == this.state.selectedAccordian) {
        newData[i].isSimCreditDaysValid =
          newData[i].simCreditDays !== '';
      }
    }

    await this.checkAllFields();
  };

  checkAllFields = async () => {
    let newData;
    newData = this.state.categoryDataForBillingPage;
    let foundCurrencyCheckArray = await newData.map(eachData => {
      if (
        eachData.simBillingId == '' ||
        eachData.simBillingPeriodId == '' ||
        eachData.simCreditDays == '' ||
        eachData.simExpiryPeriod == '' ||
        eachData.simFreeDays == '' ||
        eachData.simPacket == '' ||
        eachData.simPeriodCharge == '' ||
        eachData.simTaxPercentId == '' ||
        eachData.simTrack == '' ||
        eachData.tcuBillingId == '' ||
        eachData.tcuBillingPeriodId == '' ||
        eachData.tcuCreditDays == '' ||
        eachData.tcuExpiryPeriod == '' ||
        eachData.tcuFreeDays == '' ||
        eachData.tcuPacket == '' ||
        eachData.tcuPeriodCharge == '' ||
        eachData.tcuTaxPercentId == '' ||
        eachData.tcuTrack == ''
      ) {
        this.setState({
          billingPageInitialState: false
        });
      } else {
        this.setState({
          billingPageInitialState: true
        });
      }
    });
  };

  render() {
    const { open, onClose, client, classes } = this.props;
    const {
      isAnyRolesSelected,
      stepCount,
      steps,
      billingPageInitialState,
      customerName,
      userName,
      password,
      isCustomerNameValid,
      isCustomerEmailValid,
      emailId,
      isPhoneNumberValid,
      phoneNumber,
      isAccountNumberValid,
      accountNumber,
      isBankNameValid,
      bankName,
      isAccountHolderValid,
      accountHolder,
      isIfscValid,
      ifsc,
      isGstValid,
      gstNumber,
      isPanNumberValid,
      panNumber,
      isCountryValid,
      countryName,
      isStateValid,
      stateName,
      isCityValid,
      city,
      isPinCodeValid,
      pinCode,
      planName,
      isPlanNameValid,
      newTcuCount,
      isNewTcuCountValid,
      planStatusName,
      isPlanStatusSelected,
      isPlanTypeSelected,
      planTypeName,
      isExpiryTimeValid,
      expiryTime,
      isWarrantyTimeValid,
      warrantyTime,
      isApprovedTcuNameSelected,
      approvedTcuName,
      isAssignedTcuCountSelected,
      assignedTCUCount,
      isTotalTcuCountSelected,
      totalTcuCount,
      isTcuBilingModeValid,
      isSimBilingModeValid,
      isTcuPacketValid,
      isSimPacketValid,
      isTcuTrackValid,
      isSimTrackValid,
      isTcuBillingPeriodValid,
      isSimBillingPeriodValid,
      isTcuTaxPercentValid,
      isSimTaxPercentValid,
      isTcuPeriodChargeValid,
      isSimPeriodChargeValid,
      isTcuFreeDaysValid,
      isSimFreeDaysValid,
      isTcuExpiryPeriodValid,
      isSimExpiryPeriodValid,
      isTcuCreditDaysValid,
      isSimCreditDaysValid
    } = this.state;

    return (
      <CustomerContext.Provider value={this.state}>
        <CustomerContext.Consumer>
          {value => (
            <Modal
              open={open}
              onClose={onClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Grid container className="dialog">
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    id="modal-modal-title"
                    className="headerContainer"
                  >
                    <Grid item>
                      <Typography className="header">
                        Add New Customer
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Stepper activeStep={stepCount}>
                        {steps.map((label, index) => (
                          <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    className="formContainer"
                    id="modal-modal-description"
                  >
                    <Grid item xs={12}>
                      {stepCount == 0 ? (
                        <CustomerDetails
                          value={value}
                          onChange={this.handleInputChange}
                          handleCountryChange={
                            this.handleCountryChange
                          }
                          handleStateChange={this.handleStateChange}
                          handleCityChange={this.handleCityChange}
                          handleBankChange={this.handleBankChange}
                          checkCustomerNameValidity={
                            this.checkCustomerNameValidity
                          }
                          checkCustomerEmailValidity={
                            this.checkCustomerEmailValidity
                          }
                          checkPhoneNoValidity={
                            this.checkPhoneNoValidity
                          }
                          checkAccountNumberValidity={
                            this.checkAccountNumberValidity
                          }
                          checkBankNameValidity={
                            this.checkBankNameValidity
                          }
                          checkAccountHolderValidity={
                            this.checkAccountHolderValidity
                          }
                          checkIfscValidity={this.checkIfscValidity}
                          checkGstValidity={this.checkGstValidity}
                          checkPanNumberValidity={
                            this.checkPanNumberValidity
                          }
                          checkCountryValidity={
                            this.checkCountryValidity
                          }
                          checkStateValidity={
                            this.checkStateValidity
                          }
                          checkCityValidity={this.checkCityValidity}
                          checkPinCodeValidity={
                            this.checkPinCodeValidity
                          }
                        />
                      ) : stepCount == 1 ? (
                        <RolesAndFeatures
                          value={value}
                          handleCategoryClick={
                            this.handleCategoryClick
                          }
                          handleCheckBoxClick={
                            this.handleCheckBoxClick
                          }
                        />
                      ) : (
                        <BillingPlan
                          value={value}
                          onChange={this.handleInputChange}
                          handlePlanStatusChange={
                            this.handlePlanStatusChange
                          }
                          handlePlanTypeChange={
                            this.handlePlanTypeChange
                          }
                          handleClick={this.handleClick}
                          handlePlanInfo={this.handlePlanInfo}
                          handleCategories={this.handleCategories}
                          handleWarrantyTimeChange={
                            this.handleWarrantyTimeChange
                          }
                          handleExpiryTimeChange={
                            this.handleExpiryTimeChange
                          }
                          handleCurrencyChange={
                            this.handleCurrencyChange
                          }
                          handleTcuBillingChange={
                            this.handleTcuBillingChange
                          }
                          handleSimBillingModeChange={
                            this.handleSimBillingModeChange
                          }
                          handleTcuBillingPeriodChange={
                            this.handleTcuBillingPeriodChange
                          }
                          handleSimBillingPeriodChange={
                            this.handleSimBillingPeriodChange
                          }
                          handleTcuPercentChange={
                            this.handleTcuPercentChange
                          }
                          handleSimPercentChange={
                            this.handleSimPercentChange
                          }
                          handlePlanRecurringChange={
                            this.handlePlanRecurringChange
                          }
                          handleTcuBillingWiseChange={
                            this.handleTcuBillingWiseChange
                          }
                          handleAccordianChange={
                            this.handleAccordianChange
                          }
                          checkPlanNameValidity={
                            this.checkPlanNameValidity
                          }
                          checkNewTcuCountValidity={
                            this.checkNewTcuCountValidity
                          }
                          checkPlanStatusValidity={
                            this.checkPlanStatusValidity
                          }
                          checkPlanTypeValidity={
                            this.checkPlanTypeValidity
                          }
                          checkExpiryTimeValidity={
                            this.checkExpiryTimeValidity
                          }
                          checkWarrantyTimeValidity={
                            this.checkWarrantyTimeValidity
                          }
                          checkApprovedTcuNameValidity={
                            this.checkApprovedTcuNameValidity
                          }
                          checkAssignedTcuCountValidity={
                            this.checkAssignedTcuCountValidity
                          }
                          checkTotalTcuCountValidity={
                            this.checkTotalTcuCountValidity
                          }
                          checkTcuBilingModeValidity={
                            this.checkTcuBilingModeValidity
                          }
                          checkSimBilingModeValidity={
                            this.checkSimBilingModeValidity
                          }
                          checkTcuPacketValidity={
                            this.checkTcuPacketValidity
                          }
                          checkSimPacketValidity={
                            this.checkSimPacketValidity
                          }
                          checkTcuTrackValidity={
                            this.checkTcuTrackValidity
                          }
                          checkSimTrackValidity={
                            this.checkSimTrackValidity
                          }
                          checkTcuBillingPeriodValidity={
                            this.checkTcuBillingPeriodValidity
                          }
                          checkSimBillingPeriodValidity={
                            this.checkSimBillingPeriodValidity
                          }
                          checkTcuTaxPercentValidity={
                            this.checkTcuTaxPercentValidity
                          }
                          checkSimTaxPercentValidity={
                            this.checkSimTaxPercentValidity
                          }
                          checkTcuPeriodChargeValidity={
                            this.checkTcuPeriodChargeValidity
                          }
                          checkSimPeriodChargeValidity={
                            this.checkSimPeriodChargeValidity
                          }
                          checkTcuFreeDaysValidity={
                            this.checkTcuFreeDaysValidity
                          }
                          checkSimFreeDaysValidity={
                            this.checkSimFreeDaysValidity
                          }
                          checkTcuExpiryPeriodValidity={
                            this.checkTcuExpiryPeriodValidity
                          }
                          checkSimExpiryPeriodValidity={
                            this.checkSimExpiryPeriodValidity
                          }
                          checkTcuCreditDaysValidity={
                            this.checkTcuCreditDaysValidity
                          }
                          checkSimCreditDaysValidity={
                            this.checkSimCreditDaysValidity
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    id="modal-modal-description"
                    className="buttonContainer"
                  >
                    <Grid
                      item
                      xs={12}
                      md={4}
                      className="buttonCancel"
                    >
                      <ColorOutlinedButton
                        variant="outlined"
                        onClick={onClose}
                        className="buttonOutlined"
                      >
                        <Typography className="buttonOutlinedText">
                          Cancel
                        </Typography>
                      </ColorOutlinedButton>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Grid container justifyContent="flex-end">
                        <Grid item className="buttonBack">
                          <ColorOutlinedButton
                            variant="outlined"
                            onClick={this.handleBack}
                            className="buttonOutlined"
                            disabled={stepCount == 0 ? true : false}
                          >
                            <Typography className="buttonOutlinedText">
                              Back
                            </Typography>
                          </ColorOutlinedButton>
                        </Grid>
                        <Grid item>
                          {stepCount == 1 ? (
                            <ColorButton
                              disabled={!isAnyRolesSelected}
                              variant="outlined"
                              onClick={this.handleNext}
                              className="buttonOutlined"
                              fullWidth
                            >
                              <Typography className="buttonText">
                                Next
                              </Typography>
                            </ColorButton>
                          ) : stepCount !== 2 ? (
                            <ColorButton
                              variant="outlined"
                              onClick={this.handleNext}
                              className="buttonOutlined"
                              fullWidth
                              disabled={
                                !(isCustomerNameValid &&
                                  customerName !== '' &&
                                  userName !== '' &&
                                  password !== '' &&
                                  isCustomerEmailValid &&
                                  emailId !== '' &&
                                  isPhoneNumberValid &&
                                  phoneNumber !== '',
                                isAccountNumberValid &&
                                  accountNumber !== '' &&
                                  isBankNameValid &&
                                  bankName !== '' &&
                                  isAccountHolderValid &&
                                  accountHolder !== '' &&
                                  isIfscValid &&
                                  ifsc !== '' &&
                                  isGstValid &&
                                  gstNumber &&
                                  isPanNumberValid &&
                                  panNumber !== '' &&
                                  isCountryValid &&
                                  countryName !== '',
                                isStateValid &&
                                  stateName !== '' &&
                                  isCityValid &&
                                  city !== '' &&
                                  isPinCodeValid &&
                                  pinCode !== '')
                              }
                            >
                              <Typography className="buttonText">
                                Next
                              </Typography>
                            </ColorButton>
                          ) : (
                            <ColorButton
                              variant="outlined"
                              className="buttonOutlined"
                              fullWidth
                              onClick={this.handleSave}
                              disabled={
                                !(
                                  isPlanNameValid &&
                                  planName !== '' &&
                                  // isNewTcuCountValid &&
                                  // newTcuCount !== '' &&
                                  isPlanStatusSelected &&
                                  planStatusName !== '' &&
                                  isPlanTypeSelected &&
                                  planTypeName !== '' &&
                                  isExpiryTimeValid &&
                                  expiryTime !== '' &&
                                  isWarrantyTimeValid &&
                                  warrantyTime !== '' &&
                                  // isApprovedTcuNameSelected &&
                                  // approvedTcuName !== '' &&
                                  // isAssignedTcuCountSelected &&
                                  // assignedTCUCount !== '' &&
                                  isTotalTcuCountSelected &&
                                  totalTcuCount !== '' &&
                                  billingPageInitialState
                                )
                              }
                            >
                              <Typography className="buttonText">
                                Save
                              </Typography>
                            </ColorButton>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Modal>
          )}
        </CustomerContext.Consumer>
      </CustomerContext.Provider>
    );
  }
}

export default withApollo(withSharedSnackbar(AddCustomer));
