import { ProfilePage } from './pages/Profile';
import { SettingsPage } from './pages/Settings';
import SettingIcon from './assets/Settings.svg';
import PersonIcon from './assets/person_black_24dp.svg';
import SendIcon from './assets/send_black_24dp.svg';
import ConnectIcon from './assets/connect_without_contact_black_24dp.svg';
import PresentIcon from './assets/co_present_black_24dp.svg';
import CategoryIcon from './assets/category_black_24dp.svg';
import AccountIcon from './assets/account_tree_black_24dp.svg';
import BalanceIcon from './assets/account_balance_black_24dp.svg';
import Customer from './pages/Customers/Customer';

export const Pages = [
  {
    name: 'Settings',
    path: 'billingPlan',
    icon: CategoryIcon,
    element: <SettingsPage />
  },
  // {
  //   name: 'Settings',
  //   path: 'settings',
  //   icon: ConnectIcon,
  //   element: <SettingsPage />
  // },
  {
    name: 'Customer',
    path: 'customers',
    icon: PersonIcon,
    element: <Customer />
  }

  // {
  //   name: 'Settings',
  //   path: 'settings',
  //   icon: AccountIcon,
  //   element: <SettingsPage />
  // },
  // {
  //   name: 'Settings',
  //   path: 'settings',
  //   icon: BalanceIcon,
  //   element: <SettingsPage />
  // },
  // {
  //   name: 'Settings',
  //   path: 'settings',
  //   icon: SettingIcon,
  //   element: <SettingsPage />
  // }
];
