import { createContext, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './useLocalStorage';
import decode from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('id_token', '');
  const [loginId, setLoginId] = useLocalStorage('loginId', '');
  const [userName, setUserName] = useLocalStorage('userName', '');
  const [ownerId, setOwnerId] = useLocalStorage('ownerId', '');
  const navigate = useNavigate();

  const login = async token => {
    const { id, exp, iat, roleId, ownerId, username } =
      decode(token);
    setUser(token);
    setLoginId(id);
    setUserName(username);
    setOwnerId(ownerId);
    navigate('/dashboard', { replace: true });
  };

  const logout = () => {
    setUser('');
    navigate('/', { replace: true });
    localStorage.removeItem('id_token');
    localStorage.removeItem('loginId');
    localStorage.removeItem('userName');
    localStorage.removeItem('ownerId');
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),
    [user, loginId, userName, ownerId]
  );

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
