import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CustomTextField } from '../hoc/CustomComponents';

export const BasicPage = ({ title, icon }) => {
  return (
    <Container component="main" maxWidth="xs">
      <CustomTextField
        value="hiii"
        label="label"
        placeholder="placeHolder"
        type="text"
        onChange="onchange"
      />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          {icon}
        </Avatar>
        <Typography component="h1" variant="h5">
          {title}
        </Typography>
      </Box>
    </Container>
  );
};
