import { height } from '@mui/system';
import { Link, Navigate, useOutlet } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { AppBar } from './AppBar';

export const ProtectedLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <AppBar
      //add default components here. for eg :
      // pages={[
      //   { label: 'Settings', path: 'settings' },
      //   { label: 'Profile', path: 'profile' }
      // ]}
      />
      <div
        style={{
          marginLeft: '88px',
          marginTop: '104px',
          marginRight: '8px'
        }}
      >
        {outlet}
      </div>
    </div>
  );
};
