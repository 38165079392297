/**
 * @module graphql/queries
 * @summary Module containing customer graphql queries used throughout the app
 */

import { gql } from '@apollo/client';

/**
 * @summary Fetches all oem customers with their assigned vehcile
 */
export const GET_ALL_OEM = gql`
  query getOem($id: Int) {
    getOem(id: $id) {
      id
      oemName
      oemCode
      oemEmail
      phoneNumber
      addressLine1
      addressLine2
      bankName
      accHoldingPerson
      accNumber
      ifsc
      gst
      pan
      countryId
      countryName
      stateId
      stateName
      city
      cityName
      pincode
      userId
      logo
      ownerId
      otp
      otpExpiry
      status
      softwareWallet
      simWallet
      endPointUrl
      vehicle {
        id
        vehicleModelName
        ownerId
      }
    }
  }
`;

export const GET_ALL_COUNTRIES = gql`
  query {
    getAllCountries {
      id
      name
      iso3
      numeric_code
      iso2
      phoneCode
      capital
      currency
      currency_name
      region
      subregion
      timezones
      latitude
      longitude
      emoji
    }
  }
`;

export const GET_STATES = gql`
  query getStateByCountryId($countryId: Int!) {
    getStateByCountryId(countryId: $countryId) {
      id
      name
      country_id
      country_code
      fips_code
      iso2
      latitude
      longitude
    }
  }
`;

export const GET_CITIES = gql`
  query getCityByStateId($stateId: Int!) {
    getCityByStateId(stateId: $stateId) {
      id
      name
      state_id
      state_code
      country_id
      country_code
      latitude
      longitude
    }
  }
`;

export const GET_ROLES_FEATURES_CATEGORY = gql`
  query {
    getCategories {
      id
      iconPath
      name
      tooltip
      features {
        id
        category_id
        iconPath
        name
        tooltip
      }
    }
  }
`;

export const VALIDATE_OEM_OTP = gql`
  query validateOemOtp($otp: Int!, $oemId: Int!) {
    validateOemOtp(otp: $otp, oemId: $oemId)
  }
`;

export const SEND_OTP_TO_USER = gql`
  mutation sendOtpToUser($id: Int) {
    sendOtpToUser(id: $id)
  }
`;

export const UPDATE_VALIDATE_STATUS = gql`
  mutation updateValidateStatus($id: Int) {
    updateValidateStatus(id: $id)
  }
`;

export const CUSTOMER_ONBOARD = gql`
  mutation Mutation(
    $oemName: String!
    $oemCode: String!
    $oemEmail: String!
    $phoneNumber: String!
    $addressLine1: String!
    $ownerId: Int!
    $planName: String!
    $planType: String!
    $planExpiry: String!
    $warrantyPeriod: Int!
    $billingStatus: String!
    $addressLine2: String
    $bankName: String
    $accHoldingPerson: String
    $accNumber: String
    $ifsc: String
    $gst: String
    $pan: String
    $countryId: Int
    $stateId: Int
    $city: Int
    $pincode: Int
    $kycStatus: String
    $totalTcuCount: Int
    $dataArray: [CategoryDataObject]
    $username: String
    $password: String
  ) {
    customerOnboard(
      oemName: $oemName
      oemCode: $oemCode
      oemEmail: $oemEmail
      phoneNumber: $phoneNumber
      addressLine1: $addressLine1
      ownerId: $ownerId
      planName: $planName
      planType: $planType
      planExpiry: $planExpiry
      warrantyPeriod: $warrantyPeriod
      billingStatus: $billingStatus
      addressLine2: $addressLine2
      bankName: $bankName
      accHoldingPerson: $accHoldingPerson
      accNumber: $accNumber
      ifsc: $ifsc
      gst: $gst
      pan: $pan
      countryId: $countryId
      stateId: $stateId
      city: $city
      pincode: $pincode
      kycStatus: $kycStatus
      totalTCUCount: $totalTcuCount
      dataArray: $dataArray
      username: $username
      password: $password
    ) {
      message
      statusCode
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation deactivateOemStatus($oemId: Int!) {
    deactivateOemStatus(oemId: $oemId)
  }
`;

export const GET_CUSTOMER_DETAILS_BY_OEM_ID = gql`
  query getCustomerDetailsByOemId($oemId: Int!) {
    getCustomerDetailsByOemId(oemId: $oemId) {
      customerName
      accountHolder
      accountNumber
      address1
      address2
      bankName
      city
      cityName
      gstNumber
      ifsc
      oemCode
      emailId
      panNumber
      phoneNumber
      pinCode
      stateId
      stateName
      countryId
      countryName
      logo
      userName
      planName
      totalTcuCount
      expiryTime
      warrantyTime
      planTypeName
      planStatusName
      allCategoriesData {
        name
        categoryId
        subCategories {
          name
          featureId
          read
          create
          edit
          delete
        }
        isPlanRecurring
        isBillingTcuWise
        currencyId
        currencyName
        tcuBillingId
        tcuBillingMode
        simBillingId
        simBillingMode
        tcuPacket
        simPacket
        tcuTrack
        simTrack
        tcuBillingPeriodId
        tcuBillingPeriod
        simBillingPeriodId
        simBillingPeriod
        tcuTaxPercentId
        tcuTaxPercent
        simTaxPercentId
        simTaxPercent
        tcuPeriodCharge
        simPeriodCharge
        tcuFreeDays
        simFreeDays
        tcuExpiryPeriod
        simExpiryPeriod
        tcuCreditDays
        simCreditDays
        isTcuBilingModeValid
        isSimBilingModeValid
        isTcuPacketValid
        isSimPacketValid
        isTcuTrackValid
        isSimTrackValid
        isTcuBillingPeriodValid
        isSimBillingPeriodValid
        isTcuTaxPercentValid
        isSimTaxPercentValid
        isTcuPeriodChargeValid
        isSimPeriodChargeValid
        isTcuFreeDaysValid
        isSimFreeDaysValid
        isTcuExpiryPeriodValid
        isSimExpiryPeriodValid
        isTcuCreditDaysValid
        isSimCreditDaysValid
      }
    }
  }
`;

export const UPDATE_CUSTOMER_BY_OEMID = gql`
  mutation updateCustomerByOemId(
    $isTallyCustomer: Boolean!
    $oemId: Int!
    $oemName: String
    $oemCode: String
    $oemEmail: String
    $phoneNumber: String
    $addressLine1: String
    $addressLine2: String
    $bankName: String
    $accHoldingPerson: String
    $accNumber: String
    $ifsc: String
    $gst: String
    $pan: String
    $countryId: Int
    $stateId: Int
    $city: Int
    $pincode: Int
    $logo: String
    $ownerId: Int
    $kycStatus: String
    $planName: String
    $planType: String
    $planExpiry: String
    $warrantyPeriod: Int
    $billingStatus: String
    $totalTCUCount: Int
    $username: String
    $password: String
    $dataArray: [CategoryDataObject]
  ) {
    updateCustomerByOemId(
      isTallyCustomer: $isTallyCustomer
      oemId: $oemId
      oemName: $oemName
      oemCode: $oemCode
      oemEmail: $oemEmail
      phoneNumber: $phoneNumber
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      bankName: $bankName
      accHoldingPerson: $accHoldingPerson
      accNumber: $accNumber
      ifsc: $ifsc
      gst: $gst
      pan: $pan
      countryId: $countryId
      stateId: $stateId
      city: $city
      pincode: $pincode
      logo: $logo
      ownerId: $ownerId
      kycStatus: $kycStatus
      planName: $planName
      planType: $planType
      planExpiry: $planExpiry
      warrantyPeriod: $warrantyPeriod
      billingStatus: $billingStatus
      totalTCUCount: $totalTCUCount
      username: $username
      password: $password
      dataArray: $dataArray
    ) {
      code
      status
      message
    }
  }
`;

[
  {
    name: 'Device Lifecycle Management',
    categoryId: 1,
    subCategories: [
      {
        name: 'Analytics',
        featureId: 1,
        read: false,
        create: false,
        edit: false,
        delete: false
      },
      {
        name: 'DeviceManager',
        featureId: 2,
        read: true,
        create: true,
        edit: false,
        delete: false
      },
      {
        name: 'Device-Group-Manager',
        featureId: 3,
        read: false,
        create: false,
        edit: false,
        delete: false
      },
      {
        name: 'Sim-Management',
        featureId: 4,
        read: false,
        create: false,
        edit: false,
        delete: false
      },
      {
        name: 'OTA-Campaign',
        featureId: 5,
        read: false,
        create: false,
        edit: false,
        delete: false
      },
      {
        name: 'Data Push',
        featureId: 6,
        read: false,
        create: false,
        edit: false,
        delete: false
      }
    ],
    isPlanRecurring: false,
    isBillingTcuWise: false,
    currencyId: 'INR',
    currencyName: '1',
    tcuBillingId: 'PREPAID',
    tcuBillingMode: '1',
    simBillingId: 'PREPAID',
    simBillingMode: '1',
    tcuPacket: '3',
    simPacket: '3',
    tcuTrack: '3',
    simTrack: '3',
    tcuBillingPeriodId: 'Monthly',
    tcuBillingPeriod: '30',
    simBillingPeriodId: 'Monthly',
    simBillingPeriod: '30',
    tcuTaxPercentId: '8 %',
    tcuTaxPercent: '1',
    simTaxPercentId: '8 %',
    simTaxPercent: '1',
    tcuPeriodCharge: '3',
    simPeriodCharge: '3',
    tcuFreeDays: '3',
    simFreeDays: '3',
    tcuExpiryPeriod: '3',
    simExpiryPeriod: '3',
    tcuCreditDays: '3',
    simCreditDays: '3',
    isTcuBilingModeValid: true,
    isSimBilingModeValid: true,
    isTcuPacketValid: true,
    isSimPacketValid: true,
    isTcuTrackValid: true,
    isSimTrackValid: true,
    isTcuBillingPeriodValid: true,
    isSimBillingPeriodValid: true,
    isTcuTaxPercentValid: true,
    isSimTaxPercentValid: true,
    isTcuPeriodChargeValid: true,
    isSimPeriodChargeValid: true,
    isTcuFreeDaysValid: true,
    isSimFreeDaysValid: true,
    isTcuExpiryPeriodValid: true,
    isSimExpiryPeriodValid: true,
    isTcuCreditDaysValid: true,
    isSimCreditDaysValid: true
  },
  {
    name: 'Certification Manager',
    categoryId: 2,
    subCategories: [
      {
        name: 'Certification Manager',
        featureId: 7,
        read: false,
        create: false,
        edit: false,
        delete: false
      },
      {
        name: 'Certification Viewer',
        featureId: 8,
        read: false,
        create: false,
        edit: true,
        delete: true
      },
      {
        name: 'Analytics',
        featureId: 9,
        read: false,
        create: false,
        edit: false,
        delete: false
      }
    ],
    isPlanRecurring: false,
    isBillingTcuWise: false,
    currencyId: 'INR',
    currencyName: '1',
    tcuBillingId: 'PREPAID',
    tcuBillingMode: '1',
    simBillingId: 'PREPAID',
    simBillingMode: '1',
    tcuPacket: '4',
    simPacket: '4',
    tcuTrack: '4',
    simTrack: '4',
    tcuBillingPeriodId: 'Monthly',
    tcuBillingPeriod: '30',
    simBillingPeriodId: 'Monthly',
    simBillingPeriod: '30',
    tcuTaxPercentId: '8 %',
    tcuTaxPercent: '1',
    simTaxPercentId: '8 %',
    simTaxPercent: '1',
    tcuPeriodCharge: '4',
    simPeriodCharge: '4',
    tcuFreeDays: '4',
    simFreeDays: '4',
    tcuExpiryPeriod: '4',
    simExpiryPeriod: '4',
    tcuCreditDays: '4',
    simCreditDays: '4',
    isTcuBilingModeValid: true,
    isSimBilingModeValid: true,
    isTcuPacketValid: true,
    isSimPacketValid: true,
    isTcuTrackValid: true,
    isSimTrackValid: true,
    isTcuBillingPeriodValid: true,
    isSimBillingPeriodValid: true,
    isTcuTaxPercentValid: true,
    isSimTaxPercentValid: true,
    isTcuPeriodChargeValid: true,
    isSimPeriodChargeValid: true,
    isTcuFreeDaysValid: true,
    isSimFreeDaysValid: true,
    isTcuExpiryPeriodValid: true,
    isSimExpiryPeriodValid: true,
    isTcuCreditDaysValid: true,
    isSimCreditDaysValid: true
  }
];
