import React, { Component } from 'react';
import { FormHelperText, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  CustomTextField,
  CustomDateTimePicker,
  CustomSelect
} from '../../hoc/CustomComponents';
import BillingPlanCategories from './BillingPlanCategories';
import './BillingPlan.css';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const useStyles = makeStyles({
  Error_msg: {
    color: '#ED5B4F !important',
    height: '2px',
    font: 'normal normal 500 10px/16px Poppins',
    letterSpacing: '0px',
    opacity: '1',
    paddingTop: '0px',
    paddingLeft: '16px'
  }
});
const BillingPlan = props => {
  const {
    isEditOfExistingCustomer,
    nestedListOpen,
    selectedPlan,
    selectedSubPlan,
    planData,
    planStatusId,
    allPlanStatus,
    planStatusName,
    planTypeId,
    allPlanTypes,
    planTypeName,
    planName,
    approvedTcuName,
    assignedTcuCount,
    totalTcuCount,
    newTcuCount,
    expiryTime,
    warrantyTime,
    isPlanNameValid,
    isNewTcuCountValid,
    isPlanStatusSelected,
    isPlanTypeSelected,
    isExpiryTimeValid,
    isWarrantyTimeValid,
    isApprovedTcuNameSelected,
    isAssignedTcuCountSelected,
    isTotalTcuCountSelected
  } = props.value;
  const {
    handleClick,
    handlePlanInfo,
    handleCategories,
    handlePlanStatusChange,
    handlePlanTypeChange,
    handleExpiryTimeChange,
    handleWarrantyTimeChange,
    onChange,
    checkPlanNameValidity,
    checkNewTcuCountValidity,
    checkPlanStatusValidity,
    checkPlanTypeValidity,
    checkExpiryTimeValidity,
    checkWarrantyTimeValidity,
    checkApprovedTcuNameValidity,
    checkAssignedTcuCountValidity,
    checkTotalTcuCountValidity
  } = props;

  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          className="mainContainer"
        >
          <Grid item>
            <Typography className="billingHeader">
              Billing Plans - Plan Details
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={3} className="planListContainer">
            {planData.map(eachData => {
              return (
                <List
                  className="planListItem"
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  key={eachData.planName}
                >
                  <ListItemButton
                    onClick={() => handleClick(eachData.planName)}
                  >
                    <ListItemText primary={eachData.planName} />
                    {selectedPlan == eachData.planName &&
                    nestedListOpen ? (
                      <RemoveIcon />
                    ) : (
                      <AddIcon />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={
                      selectedPlan == eachData.planName &&
                      nestedListOpen
                    }
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      <ListItemButton
                        className="collapseListItem"
                        onClick={() => handlePlanInfo()}
                      >
                        <Grid container>
                          <Grid item xs={2}>
                            {selectedSubPlan == 'Plan Info' ? (
                              <ListItemIcon>
                                <NavigateNextIcon className="nextIcon" />
                              </ListItemIcon>
                            ) : null}
                          </Grid>
                          <Grid item xs={10}>
                            <ListItemText
                              primary="Plan Info"
                              className={
                                selectedSubPlan == 'Plan Info'
                                  ? 'subPlanSelectedColor'
                                  : null
                              }
                            />
                          </Grid>
                        </Grid>
                      </ListItemButton>
                      <ListItemButton
                        className="collapseListItem"
                        onClick={() => handleCategories()}
                      >
                        <Grid container>
                          <Grid item xs={2}>
                            {selectedSubPlan == 'Categories' ? (
                              <ListItemIcon>
                                <NavigateNextIcon className="nextIcon" />
                              </ListItemIcon>
                            ) : null}
                          </Grid>
                          <Grid item xs={10}>
                            <ListItemText
                              primary="Categories"
                              className={
                                selectedSubPlan == 'Categories'
                                  ? 'subPlanSelectedColor'
                                  : null
                              }
                            />
                          </Grid>
                        </Grid>
                      </ListItemButton>
                    </List>
                  </Collapse>
                </List>
              );
            })}
          </Grid>

          <Grid item xs={12} md={9} className="rightMainContainer">
            {selectedSubPlan != '' ? (
              selectedSubPlan == 'Plan Info' ? (
                //  Plan Page
                <Grid container className="rightMainSubContainer">
                  <Grid item xs={12}>
                    <Typography>{selectedSubPlan}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={2}
                      className="containerPadding"
                    >
                      <Grid item xs={12} md={6} lg={6}>
                        <CustomTextField
                          value={planName}
                          name="planName"
                          label="Plan Name *"
                          placeholder="Enter the Plan Name"
                          type="text"
                          onChange={onChange}
                          error={!isPlanNameValid}
                          onBlur={checkPlanNameValidity}
                        />
                        <FormHelperText
                          className={classes.Error_msg}
                        >
                          {!isPlanNameValid
                            ? 'Plan name should be alphabets between 3 to 45 characters'
                            : ''}
                        </FormHelperText>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <CustomTextField
                          value={totalTcuCount}
                          name="totalTcuCount"
                          label="Total TCU Count *"
                          placeholder="Total TCU Count"
                          type="number"
                          onChange={onChange}
                          error={!isTotalTcuCountSelected}
                          onBlur={checkTotalTcuCountValidity}
                        />
                        <FormHelperText
                          className={classes.Error_msg}
                        >
                          {!isTotalTcuCountSelected
                            ? 'Please enter total TCU count'
                            : ''}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      className="containerPadding"
                    >
                      <Grid item xs={12} md={6} lg={6}>
                        <CustomSelect
                          value={planStatusId}
                          label="Status *"
                          placeholder="Select the Status"
                          options={allPlanStatus}
                          handleSelectChange={handlePlanStatusChange}
                          name="planStatusName"
                          onChange={onChange}
                          inputValue={planStatusName}
                          error={!isPlanStatusSelected}
                          onBlur={checkPlanStatusValidity}
                        />
                        <FormHelperText
                          className={classes.Error_msg}
                        >
                          {!isPlanStatusSelected
                            ? 'Please select status'
                            : ''}
                        </FormHelperText>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <CustomSelect
                          value={planTypeId}
                          label="Plan Type *"
                          placeholder="Select the Plan Type"
                          options={allPlanTypes}
                          handleSelectChange={handlePlanTypeChange}
                          name="planTypeName"
                          onChange={onChange}
                          inputValue={planTypeName}
                          error={!isPlanTypeSelected}
                          onBlur={checkPlanTypeValidity}
                        />
                        <FormHelperText
                          className={classes.Error_msg}
                        >
                          {!isPlanTypeSelected
                            ? 'Please select plan type'
                            : ''}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      className="containerPadding"
                    >
                      <Grid item xs={12} md={6} lg={6}>
                        <CustomTextField
                          value={
                            isEditOfExistingCustomer
                              ? ''
                              : expiryTime
                          }
                          disabled={isEditOfExistingCustomer}
                          name="expiryTime"
                          label="Plan Expiry Period (in Months) *"
                          placeholder={
                            isEditOfExistingCustomer
                              ? ''
                              : 'Enter the Plan Expiry Period'
                          }
                          type="number"
                          onChange={onChange}
                          error={!isExpiryTimeValid}
                          onBlur={checkExpiryTimeValidity}
                        />
                        <FormHelperText
                          className={classes.Error_msg}
                        >
                          {!isExpiryTimeValid
                            ? 'Please enter expiry time'
                            : ''}
                        </FormHelperText>
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <CustomTextField
                          value={
                            isEditOfExistingCustomer
                              ? ''
                              : warrantyTime
                          }
                          disabled={isEditOfExistingCustomer}
                          name="warrantyTime"
                          label="Warranty Period (in Months) *"
                          placeholder={
                            isEditOfExistingCustomer
                              ? ''
                              : 'Enter the Warranty Period'
                          }
                          type="number"
                          onChange={onChange}
                          error={!isWarrantyTimeValid}
                          onBlur={checkWarrantyTimeValidity}
                        />
                        <FormHelperText
                          className={classes.Error_msg}
                        >
                          {!isWarrantyTimeValid
                            ? 'Please enter expiry time'
                            : ''}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                    {/* <Grid
                      container
                      spacing={2}
                      className="containerPadding"
                    >
                      <Grid item xs={12} md={6} lg={6}>
                        <CustomTextField
                          value={approvedTcuName}
                          name="approvedTcuName"
                          label="Approved TCU Count *"
                          placeholder="Approved TCU Count"
                          type="number"
                          onChange={onChange}
                          error={!isApprovedTcuNameSelected}
                          onBlur={checkApprovedTcuNameValidity}
                        />
                        <FormHelperText
                          className={classes.Error_msg}
                        >
                          {!isApprovedTcuNameSelected
                            ? 'Please enter approved TCU count'
                            : ''}
                        </FormHelperText>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <CustomTextField
                          value={assignedTcuCount}
                          name="assignedTcuCount"
                          label="Assigned TCU Count *"
                          placeholder="Assigned TCU Count"
                          type="number"
                          onChange={onChange}
                          error={!isAssignedTcuCountSelected}
                          onBlur={checkAssignedTcuCountValidity}
                        />
                        <FormHelperText
                          className={classes.Error_msg}
                        >
                          {!isAssignedTcuCountSelected
                            ? 'Please enter assigned TCU count'
                            : ''}
                        </FormHelperText>
                      </Grid>
                    </Grid> 
                    <Grid
                      container
                      spacing={2}
                      className="containerPadding"
                    >
                      <Grid item xs={12} md={6} lg={6}>
                        <CustomTextField
                          value={totalTcuCount}
                          name="totalTcuCount"
                          label="Total TCU Count *"
                          placeholder="Total TCU Count"
                          type="number"
                          onChange={onChange}
                          error={!isTotalTcuCountSelected}
                          onBlur={checkTotalTcuCountValidity}
                        />
                        <FormHelperText
                          className={classes.Error_msg}
                        >
                          {!isTotalTcuCountSelected
                            ? 'Please enter total TCU count'
                            : ''}
                        </FormHelperText>
                      </Grid>
                    </Grid> */}
                  </Grid>
                </Grid>
              ) : (
                //   Category Page
                <Grid container className="rightMainSubContainer">
                  <Grid item xs={12}>
                    <Typography>{selectedSubPlan}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <BillingPlanCategories props={props} />
                  </Grid>
                </Grid>
              )
            ) : (
              // Message, if no plan field is slected
              <Grid container className="rightMainSubContainer">
                <Grid item xs={12} className="noFormMessage">
                  <Typography textAlign="center">
                    Please select a Plan Field to view it's details.
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BillingPlan;
