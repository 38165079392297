import { Toolbar, AppBar, IconButton } from '@mui/material';
import React, { useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import { makeStyles } from "@mui/styles";
import image from '../../assets/zeliot-logo.png';
import AccountMenu from './AccountMenu/AccountMenu';

export default function NavBar() {
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
    setIsAccountMenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      style={{
        background: '#FFFFFE 0% 0% no-repeat padding-box',
        opacity: 1,
        width: '100vw',
        height: '80px',
        zIndex: 1300
      }}
    >
      <Toolbar
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          zIndex: '4000 ! important'
        }}
      >
        <div>
          <img
            src={image}
            style={{ width: '40%', margin: '24px 0 24px 24px' }}
          ></img>
        </div>

        <div>
          <IconButton onClick={handleMenu}>
            <AccountCircleIcon fontSize="large" />
          </IconButton>
          <AccountMenu
            isAccountMenuOpen={isAccountMenuOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
          />
        </div>
      </Toolbar>
    </AppBar>
  );
}
