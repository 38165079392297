import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  concat,
  createHttpLink
} from '@apollo/client';
// import { logout } from "../hooks/useAuth";
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
console.log(process.env.REACT_APP_OEM_BACKEND_URL);
const httpLink = createHttpLink(
  {
    uri: process.env.REACT_APP_OEM_BACKEND_URL
  },
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      console.log('inside if');
      graphQLErrors.forEach(error => {
        console.log(error, 'eror');
        if (error.message.toLowerCase().includes('auth')) {
          // logout();//define logout fn
        }
      });
    }
    // if (networkError.statusCode === 401) console.log('Network Error');
  })
);

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = localStorage.getItem('id_token');

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : ''
    }
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink)
});
