import { Routes, Route } from 'react-router-dom';
import { LoginPage } from './pages/Login/Login';
import { HomePage } from './pages/Home';
import { ProfilePage } from './pages/Profile';
import { SettingsPage } from './pages/Settings';
import { ProtectedLayout } from './components/ProtectedLayout';
import { HomeLayout } from './components/HomeLayout';
import './styles.css';
import { Pages } from './pagesConfig';
import { StyledEngineProvider } from '@mui/material';
import { SharedSnackbarProvider } from './hoc/Snackbar/SharedSnackbar/SharedSnackbar.context';
import LandingPage from './components/LandingPage/LandingPage';

export default function App() {
  return (
    <StyledEngineProvider injectFirst>
      <SharedSnackbarProvider>
        <Routes>
          {/* <Route element={<HomeLayout />}> */}
          {/* add LoginPage here */}
          <Route path="/" element={<LandingPage />} />
          {/* </Route> */}
          {/* //home page must be at the top -- for an outlet. */}
          <Route path="/dashboard" element={<ProtectedLayout />}>
            {Pages.map((page, index) => (
              <Route
                key={index}
                path={page.path}
                element={page.element}
              />
            ))}
          </Route>
        </Routes>
      </SharedSnackbarProvider>
    </StyledEngineProvider>
  );
}
