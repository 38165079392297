import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import './BillingPlanCategories.css';
import { FormHelperText, Grid, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from '@mui/material';
import Switch from '@mui/material/Switch';
import {
  CustomSelect,
  CustomTextField
} from '../../hoc/CustomComponents';

const useStyles = makeStyles({
  Error_msg: {
    color: '#ED5B4F !important',
    height: '2px',
    font: 'normal normal 500 10px/16px Poppins',
    letterSpacing: '0px',
    opacity: '1',
    paddingTop: '0px'
  }
});
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)'
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#5CB593'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box'
  }
}));

const BillingPlanCategories = props => {
  const {
    categoryDataForBillingPage,
    selectedAccordian,
    currencyName,
    allCurrency,
    tcuBillingMode,
    allTcuBillingModes,
    simBillingMode,
    allSimBillingModes,
    tcuBillingPeriod,
    allTcuBillingPeriods,
    simBillingPeriod,
    allSimBillingPeriods,
    tcuTaxPercent,
    allTcuTaxPercents,
    simTaxPercent,
    allSimTaxPercents,
    isTcuBilingModeValid,
    isSimBilingModeValid,
    isTcuPacketValid,
    isSimPacketValid,
    isTcuTrackValid,
    isSimTrackValid,
    isTcuBillingPeriodValid,
    isSimBillingPeriodValid,
    isTcuTaxPercentValid,
    isSimTaxPercentValid,
    isTcuPeriodChargeValid,
    isSimPeriodChargeValid,
    isTcuFreeDaysValid,
    isSimFreeDaysValid,
    isTcuExpiryPeriodValid,
    isSimExpiryPeriodValid,
    isTcuCreditDaysValid,
    isSimCreditDaysValid
  } = props.props.value;

  const {
    onChange,
    handlePlanRecurringChange,
    handleTcuBillingWiseChange,
    handleCurrencyChange,
    handleTcuBillingChange,
    handleSimBillingModeChange,
    handleTcuBillingPeriodChange,
    handleSimBillingPeriodChange,
    handleTcuPercentChange,
    handleSimPercentChange,
    handleAccordianChange,
    checkTcuBilingModeValidity,
    checkSimBilingModeValidity,
    checkTcuPacketValidity,
    checkSimPacketValidity,
    checkTcuTrackValidity,
    checkSimTrackValidity,
    checkTcuBillingPeriodValidity,
    checkSimBillingPeriodValidity,
    checkTcuTaxPercentValidity,
    checkSimTaxPercentValidity,
    checkTcuPeriodChargeValidity,
    checkSimPeriodChargeValidity,
    checkTcuFreeDaysValidity,
    checkSimFreeDaysValidity,
    checkTcuExpiryPeriodValidity,
    checkSimExpiryPeriodValidity,
    checkTcuCreditDaysValidity,
    checkSimCreditDaysValidity
  } = props.props;

  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleAccordianExpand = accordian => (event, isExpanded) => {
    setExpanded(isExpanded ? accordian : false);
  };

  return (
    <div>
      {categoryDataForBillingPage.map(eachData => {
        return (
          <Grid container key={eachData.name}>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              className="mainContainer"
            >
              <Accordion
                expanded={expanded === eachData.name}
                onChange={handleAccordianExpand(eachData.name)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  onClick={() =>
                    handleAccordianChange(eachData.name)
                  }
                >
                  <Typography>{eachData.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid
                          item
                          xs={9}
                          className="planAndBillingText"
                        >
                          <Typography variant="body2">
                            Is the plan recurring?
                          </Typography>
                        </Grid>
                        <Grid item xs={3} className="boxBorder">
                          <Grid
                            container
                            justifyContent="center"
                            className="switchPadding"
                          >
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Typography>NO</Typography>
                              <AntSwitch
                                checked={eachData.isPlanRecurring}
                                onChange={handlePlanRecurringChange}
                                inputProps={{
                                  'aria-label': 'ant design'
                                }}
                              />
                              <Typography>YES</Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid
                          item
                          xs={9}
                          className="planAndBillingText"
                        >
                          <Typography variant="body2">
                            Is the billing TCU wise?
                          </Typography>
                        </Grid>
                        <Grid item xs={3} className="boxBorder">
                          <Grid
                            container
                            justifyContent="center"
                            className="switchPadding"
                          >
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Typography>NO</Typography>
                              <AntSwitch
                                checked={eachData.isBillingTcuWise}
                                onChange={handleTcuBillingWiseChange}
                                inputProps={{
                                  'aria-label': 'ant design'
                                }}
                              />
                              <Typography>YES</Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className="boxBorder"
                      >
                        <Grid item xs={9} className="textBox">
                          <Typography variant="body2">
                            Enter the currency
                          </Typography>
                        </Grid>
                        <Grid item xs={3} className="boxBorder">
                          <Grid container justifyContent="center">
                            <CustomSelect
                              value={eachData.currencyId}
                              name="currencyName"
                              placeholder="Currency"
                              options={allCurrency}
                              handleSelectChange={
                                handleCurrencyChange
                              }
                              onChange={onChange}
                              inputValue={currencyName}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className="columnHeading">
                      <Grid container direction="row">
                        <Grid item xs={6}>
                          <Typography>Billing Criteria</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Grid container justifyContent="center">
                            <Typography>TCU</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={3}>
                          <Grid container justifyContent="center">
                            <Typography>SIM</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction="row">
                        <Grid
                          item
                          xs={6}
                          alignItems="center"
                          className="boxBorder"
                        >
                          <Grid
                            container
                            alignItems="center"
                            className="textBox"
                          >
                            <Typography variant="body2">
                              Enter Billing Mode*
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className={
                            eachData.isTcuBilingModeValid
                              ? 'boxBorder'
                              : 'boxBorderWithHeight'
                          }
                        >
                          <Grid container justifyContent="center">
                            <CustomSelect
                              value={eachData.tcuBillingId}
                              name="tcuBillingMode"
                              placeholder="TCU Billing Mode"
                              options={allTcuBillingModes}
                              handleSelectChange={
                                handleTcuBillingChange
                              }
                              onChange={onChange}
                              inputValue={tcuBillingMode}
                              error={!isTcuBilingModeValid}
                              onBlur={checkTcuBilingModeValidity}
                            />
                            <FormHelperText
                              className={classes.Error_msg}
                            >
                              {!eachData.isTcuBilingModeValid &&
                              eachData.name == selectedAccordian
                                ? 'Please select TCU Billing Mode'
                                : ''}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className={
                            eachData.isSimBilingModeValid
                              ? 'boxBorder'
                              : 'boxBorderWithHeight'
                          }
                        >
                          <Grid container justifyContent="center">
                            <CustomSelect
                              value={eachData.simBillingId}
                              name="simBillingMode"
                              placeholder="SIM Billing Mode"
                              options={allSimBillingModes}
                              handleSelectChange={
                                handleSimBillingModeChange
                              }
                              onChange={onChange}
                              inputValue={simBillingMode}
                              error={!isSimBilingModeValid}
                              onBlur={checkSimBilingModeValidity}
                            />
                            <FormHelperText
                              className={classes.Error_msg}
                            >
                              {!eachData.isSimBilingModeValid &&
                              eachData.name == selectedAccordian
                                ? 'Please select Sim Billing Mode'
                                : ''}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction="row">
                        <Grid item xs={6} className="boxBorder">
                          <Grid
                            container
                            alignItems="center"
                            className="textBox"
                          >
                            <Typography variant="body2">
                              Enter Minimum Packet/Day*
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className={
                            eachData.isTcuPacketValid
                              ? 'boxBorder'
                              : 'boxBorderWithHeight'
                          }
                        >
                          <Grid container justifyContent="center">
                            <CustomTextField
                              value={eachData.tcuPacket}
                              name="tcuPacket"
                              placeholder="TCU Packet"
                              type="number"
                              onChange={onChange}
                              error={!isTcuPacketValid}
                              onBlur={checkTcuPacketValidity}
                            />
                            <FormHelperText
                              className={classes.Error_msg}
                            >
                              {!eachData.isTcuPacketValid &&
                              eachData.name == selectedAccordian
                                ? 'Please enter TCU Packet/Day'
                                : ''}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className={
                            eachData.isSimPacketValid
                              ? 'boxBorder'
                              : 'boxBorderWithHeight'
                          }
                        >
                          <Grid container justifyContent="center">
                            <CustomTextField
                              value={eachData.simPacket}
                              name="simPacket"
                              placeholder="Sim Packet"
                              type="number"
                              onChange={onChange}
                              error={!isSimPacketValid}
                              onBlur={checkSimPacketValidity}
                            />
                            <FormHelperText
                              className={classes.Error_msg}
                            >
                              {!eachData.isSimPacketValid &&
                              eachData.name == selectedAccordian
                                ? 'Please enter Sim Packet/Day'
                                : ''}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction="row">
                        <Grid item xs={6} className="boxBorder">
                          <Grid
                            container
                            alignItems="center"
                            className="textBox"
                          >
                            <Typography variant="body2">
                              Enter Minimum Track Days*
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className={
                            eachData.isTcuTrackValid
                              ? 'boxBorder'
                              : 'boxBorderWithHeight'
                          }
                        >
                          <Grid container justifyContent="center">
                            <CustomTextField
                              value={eachData.tcuTrack}
                              name="tcuTrack"
                              placeholder="TCU Track"
                              type="number"
                              onChange={onChange}
                              error={!isTcuTrackValid}
                              onBlur={checkTcuTrackValidity}
                            />
                            <FormHelperText
                              className={classes.Error_msg}
                            >
                              {!eachData.isTcuTrackValid &&
                              eachData.name == selectedAccordian
                                ? 'Please enter TCU Track Days'
                                : ''}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className={
                            eachData.isSimTrackValid
                              ? 'boxBorder'
                              : 'boxBorderWithHeight'
                          }
                        >
                          <Grid container justifyContent="center">
                            <CustomTextField
                              value={eachData.simTrack}
                              name="simTrack"
                              placeholder="Sim Packet"
                              type="number"
                              onChange={onChange}
                              error={!isSimTrackValid}
                              onBlur={checkSimTrackValidity}
                            />
                            <FormHelperText
                              className={classes.Error_msg}
                            >
                              {!eachData.isSimTrackValid &&
                              eachData.name == selectedAccordian
                                ? 'Please enter Sim Track Days'
                                : ''}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction="row">
                        <Grid item xs={6} className="boxBorder">
                          <Grid
                            container
                            alignItems="center"
                            className="textBox"
                          >
                            <Typography variant="body2">
                              Enter Billing Period*
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className={
                            eachData.isTcuBillingPeriodValid
                              ? 'boxBorder'
                              : 'boxBorderWithHeight'
                          }
                        >
                          <Grid container justifyContent="center">
                            <CustomSelect
                              value={eachData.tcuBillingPeriodId}
                              name="tcuBillingPeriod"
                              placeholder="TCU Billing Period"
                              options={allTcuBillingPeriods}
                              handleSelectChange={
                                handleTcuBillingPeriodChange
                              }
                              onChange={onChange}
                              inputValue={tcuBillingPeriod}
                              error={!isTcuBillingPeriodValid}
                              onBlur={checkTcuBillingPeriodValidity}
                            />
                            <FormHelperText
                              className={classes.Error_msg}
                            >
                              {!eachData.isTcuBillingPeriodValid &&
                              eachData.name == selectedAccordian
                                ? 'Please select TCU Billing Period'
                                : ''}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className={
                            eachData.isSimBillingPeriodValid
                              ? 'boxBorder'
                              : 'boxBorderWithHeight'
                          }
                        >
                          <Grid container justifyContent="center">
                            <CustomSelect
                              value={eachData.simBillingPeriodId}
                              name="simBillingPeriod"
                              placeholder="SIM Billing Period"
                              options={allSimBillingPeriods}
                              handleSelectChange={
                                handleSimBillingPeriodChange
                              }
                              onChange={onChange}
                              inputValue={simBillingPeriod}
                              error={!isSimBillingPeriodValid}
                              onBlur={checkSimBillingPeriodValidity}
                            />
                            <FormHelperText
                              className={classes.Error_msg}
                            >
                              {!eachData.isSimBillingPeriodValid &&
                              eachData.name == selectedAccordian
                                ? 'Please select Sim Billing Period'
                                : ''}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction="row">
                        <Grid item xs={6} className="boxBorder">
                          <Grid
                            container
                            alignItems="center"
                            className="textBox"
                          >
                            <Typography variant="body2">
                              Select Tax Percentage*
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className={
                            eachData.isTcuTaxPercentValid
                              ? 'boxBorder'
                              : 'boxBorderWithHeight'
                          }
                        >
                          <Grid container justifyContent="center">
                            <CustomSelect
                              value={eachData.tcuTaxPercentId}
                              name="tcuTaxPercent"
                              placeholder="TCU Tax Percentage"
                              options={allTcuTaxPercents}
                              handleSelectChange={
                                handleTcuPercentChange
                              }
                              onChange={onChange}
                              inputValue={tcuTaxPercent}
                              error={!isTcuTaxPercentValid}
                              onBlur={checkTcuTaxPercentValidity}
                            />
                            <FormHelperText
                              className={classes.Error_msg}
                            >
                              {!eachData.isTcuTaxPercentValid &&
                              eachData.name == selectedAccordian
                                ? 'Please select TCU Tax Percent'
                                : ''}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className={
                            eachData.isSimTaxPercentValid
                              ? 'boxBorder'
                              : 'boxBorderWithHeight'
                          }
                        >
                          <Grid container justifyContent="center">
                            <CustomSelect
                              value={eachData.simTaxPercentId}
                              name="simTaxPercent"
                              placeholder="SIM Tax Percentage"
                              options={allSimTaxPercents}
                              handleSelectChange={
                                handleSimPercentChange
                              }
                              onChange={onChange}
                              inputValue={simTaxPercent}
                              error={!isSimTaxPercentValid}
                              onBlur={checkSimTaxPercentValidity}
                            />
                            <FormHelperText
                              className={classes.Error_msg}
                            >
                              {!eachData.isSimTaxPercentValid &&
                              eachData.name == selectedAccordian
                                ? 'Please select Sim Tax Percent'
                                : ''}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction="row">
                        <Grid item xs={6} className="boxBorder">
                          <Grid
                            container
                            alignItems="center"
                            className="textBox"
                          >
                            <Typography variant="body2">
                              Enter Charges of Period*
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className={
                            eachData.isTcuPeriodChargeValid
                              ? 'boxBorder'
                              : 'boxBorderWithHeight'
                          }
                        >
                          <Grid container justifyContent="center">
                            <CustomTextField
                              value={eachData.tcuPeriodCharge}
                              name="tcuPeriodCharge"
                              placeholder="TCU Period Charge"
                              type="number"
                              onChange={onChange}
                              error={!isTcuPeriodChargeValid}
                              onBlur={checkTcuPeriodChargeValidity}
                            />
                            <FormHelperText
                              className={classes.Error_msg}
                            >
                              {!eachData.isTcuPeriodChargeValid &&
                              eachData.name == selectedAccordian
                                ? 'Please enter TCU Period Charge'
                                : ''}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className={
                            eachData.isSimPeriodChargeValid
                              ? 'boxBorder'
                              : 'boxBorderWithHeight'
                          }
                        >
                          <Grid container justifyContent="center">
                            <CustomTextField
                              value={eachData.simPeriodCharge}
                              name="simPeriodCharge"
                              placeholder="SIM Period Charge"
                              type="number"
                              onChange={onChange}
                              error={!isSimPeriodChargeValid}
                              onBlur={checkSimPeriodChargeValidity}
                            />
                            <FormHelperText
                              className={classes.Error_msg}
                            >
                              {!eachData.isSimPeriodChargeValid &&
                              eachData.name == selectedAccordian
                                ? 'Please enter Sim Period Charge'
                                : ''}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction="row">
                        <Grid item xs={6} className="boxBorder">
                          <Grid
                            container
                            alignItems="center"
                            className="textBox"
                          >
                            <Typography variant="body2">
                              Enter Number of Free Days*
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className={
                            eachData.isTcuFreeDaysValid
                              ? 'boxBorder'
                              : 'boxBorderWithHeight'
                          }
                        >
                          <Grid container justifyContent="center">
                            <CustomTextField
                              value={eachData.tcuFreeDays}
                              name="tcuFreeDays"
                              placeholder="TCU Free Days"
                              type="number"
                              onChange={onChange}
                              error={!isTcuFreeDaysValid}
                              onBlur={checkTcuFreeDaysValidity}
                            />
                            <FormHelperText
                              className={classes.Error_msg}
                            >
                              {!eachData.isTcuFreeDaysValid &&
                              eachData.name == selectedAccordian
                                ? 'Please enter TCU free days'
                                : ''}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className={
                            eachData.isSimFreeDaysValid
                              ? 'boxBorder'
                              : 'boxBorderWithHeight'
                          }
                        >
                          <Grid container justifyContent="center">
                            <CustomTextField
                              value={eachData.simFreeDays}
                              name="simFreeDays"
                              placeholder="Sim Free Days"
                              type="number"
                              onChange={onChange}
                              error={!isSimFreeDaysValid}
                              onBlur={checkSimFreeDaysValidity}
                            />
                            <FormHelperText
                              className={classes.Error_msg}
                            >
                              {!eachData.isSimFreeDaysValid &&
                              eachData.name == selectedAccordian
                                ? 'Please enter Sim free days'
                                : ''}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction="row">
                        <Grid item xs={6} className="boxBorder">
                          <Grid
                            container
                            alignItems="center"
                            className="textBox"
                          >
                            <Typography variant="body2">
                              Enter Expiry Period (in Days)*
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className={
                            eachData.isTcuExpiryPeriodValid
                              ? 'boxBorder'
                              : 'boxBorderWithHeight'
                          }
                        >
                          <Grid container justifyContent="center">
                            <CustomTextField
                              value={eachData.tcuExpiryPeriod}
                              name="tcuExpiryPeriod"
                              placeholder="TCU Expiry Period"
                              type="number"
                              onChange={onChange}
                              error={!isTcuExpiryPeriodValid}
                              onBlur={checkTcuExpiryPeriodValidity}
                            />
                            <FormHelperText
                              className={classes.Error_msg}
                            >
                              {!eachData.isTcuExpiryPeriodValid &&
                              eachData.name == selectedAccordian
                                ? 'Please enter TCU expiry period'
                                : ''}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className={
                            eachData.isSimExpiryPeriodValid
                              ? 'boxBorder'
                              : 'boxBorderWithHeight'
                          }
                        >
                          <Grid container justifyContent="center">
                            <CustomTextField
                              value={eachData.simExpiryPeriod}
                              name="simExpiryPeriod"
                              placeholder="Sim Expiry Period"
                              type="number"
                              onChange={onChange}
                              error={!isSimExpiryPeriodValid}
                              onBlur={checkSimExpiryPeriodValidity}
                            />
                            <FormHelperText
                              className={classes.Error_msg}
                            >
                              {!eachData.isSimExpiryPeriodValid &&
                              eachData.name == selectedAccordian
                                ? 'Please enter Sim expiry period'
                                : ''}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction="row">
                        <Grid item xs={6} className="boxBorder">
                          <Grid
                            container
                            alignItems="center"
                            className="textBox"
                          >
                            <Typography variant="body2">
                              Enter Number of Credit Days*
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className={
                            eachData.isTcuCreditDaysValid
                              ? 'boxBorder'
                              : 'boxBorderWithHeight'
                          }
                        >
                          <Grid container justifyContent="center">
                            <CustomTextField
                              value={eachData.tcuCreditDays}
                              name="tcuCreditDays"
                              placeholder="TCU Credit days"
                              type="number"
                              onChange={onChange}
                              error={!isTcuCreditDaysValid}
                              onBlur={checkTcuCreditDaysValidity}
                            />
                            <FormHelperText
                              className={classes.Error_msg}
                            >
                              {!eachData.isTcuCreditDaysValid &&
                              eachData.name == selectedAccordian
                                ? 'Please enter TCU credit days'
                                : ''}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className={
                            eachData.isSimCreditDaysValid
                              ? 'boxBorder'
                              : 'boxBorderWithHeight'
                          }
                        >
                          <Grid container justifyContent="center">
                            <CustomTextField
                              value={eachData.simCreditDays}
                              name="simCreditDays"
                              placeholder="Sim Credit Days"
                              type="number"
                              onChange={onChange}
                              error={!isSimCreditDaysValid}
                              onBlur={checkSimCreditDaysValidity}
                            />
                            <FormHelperText
                              className={classes.Error_msg}
                            >
                              {!eachData.isSimCreditDaysValid &&
                              eachData.name == selectedAccordian
                                ? 'Please enter Sim credit days'
                                : ''}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};

export default BillingPlanCategories;
