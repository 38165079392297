import React from 'react';
import { Grid, Modal, Typography, Box } from '@mui/material';
import withApollo from '../../client/withApollo';
import { ColorButton } from '../../hoc/PaletteComponent';
import './DeleteCustomer.css';

const DeleteCustomer = props => {
  const { open, onClose, deleteCustomer } = props;

  return (
    <Modal
      open={open}
      disableAutoFocus={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modalStyle"
    >
      <Grid container xs={5} className="mainContainer">
        <Grid item xs={12}>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            className="header"
          >
            Delete Customer
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure ? You want to delete this customer ?
          </Typography>
        </Grid>
        <Grid item xs={12} className="allButtonsMainItem">
          <Grid container>
            <Grid item xs={6} className="buttonItem">
              <ColorButton
                variant="contained"
                onClick={onClose}
                className="button"
              >
                <Typography className="buttonText">
                  Cancel
                </Typography>
              </ColorButton>
            </Grid>
            <Grid item xs={6} className="buttonItem">
              <ColorButton
                variant="contained"
                onClick={deleteCustomer}
                className="button"
              >
                <Typography className="buttonText">
                  Delete
                </Typography>
              </ColorButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default withApollo(DeleteCustomer);
