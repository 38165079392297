import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination,
  TableRow
} from '@mui/material';
import './RolesAndFeatures.css';
import { CustomCheckbox } from '../../hoc/CustomComponents';

const RolesAndFeatures = props => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const {
    isCategorySelected,
    selectedCategory,
    allCategoriesData,
    categoryColumns,
    categoryRows,
    requiredSubCategoriesForTable
  } = props.value;
  const { handleCategoryClick, handleCheckBoxClick } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className="rolesHeader">
              Roles &amp; Features
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={3} className="categoryList">
            {allCategoriesData &&
              allCategoriesData.length &&
              allCategoriesData.map((eachData, index) => {
                return (
                  <Grid
                    container
                    justifyContent="start"
                    className="categoryListItem"
                    key={eachData.name}
                    onClick={() =>
                      handleCategoryClick(eachData.name, index)
                    }
                  >
                    <Grid item>
                      <Paper
                        elevation={0}
                        className="categoryListItemText"
                      >
                        <Typography
                          className={
                            eachData.name == selectedCategory
                              ? 'categoryListItemTextColor'
                              : null
                          }
                        >
                          {eachData.name}
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
          <Grid item xs={12} md={9} className="categoryDetailBox">
            {!isCategorySelected ? (
              <Grid container>
                <Grid item xs={12} className="noTableMessage">
                  <Typography textAlign="center">
                    Please select a Category to view it's details.
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid container className="categoryDetailBoxBorder">
                <Grid item>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography className="tableTitle">
                        {selectedCategory}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Paper className="tableContainer">
                    <TableContainer className="table">
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {categoryColumns &&
                              categoryColumns.length &&
                              categoryColumns.map(column => (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  width={column.minWidth}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedCategory &&
                            requiredSubCategoriesForTable
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map(row => {
                                let rowName = row.name;
                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.name}
                                  >
                                    {categoryColumns &&
                                      categoryColumns.length &&
                                      categoryColumns.map(column => {
                                        const value = row.name;
                                        const columnName = column.id;

                                        return (
                                          <TableCell
                                            key={column.label}
                                            align="center"
                                          >
                                            {column.label ==
                                            'Sub-Features' ? (
                                              value
                                            ) : (column.label ==
                                                'Read' &&
                                                row.read == true) ||
                                              (column.label ==
                                                'Create' &&
                                                row.create ==
                                                  true) ||
                                              (column.label ==
                                                'Edit' &&
                                                row.edit == true) ||
                                              (column.label ==
                                                'Delete' &&
                                                row.delete ==
                                                  true) ? (
                                              <CustomCheckbox
                                                checked
                                                onChange={event => {
                                                  handleCheckBoxClick(
                                                    columnName,
                                                    rowName
                                                  );
                                                }}
                                              />
                                            ) : (
                                              <CustomCheckbox
                                                checked={false}
                                                onChange={event => {
                                                  handleCheckBoxClick(
                                                    columnName,
                                                    rowName
                                                  );
                                                }}
                                              />
                                            )}
                                          </TableCell>
                                        );
                                      })}
                                  </TableRow>
                                );
                              })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={requiredSubCategoriesForTable.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Paper>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RolesAndFeatures;
