/**
 * @module AccountMenu
 * @summary This module exports the main account menu component
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Typography,
  Popover,
  Card,
  CardContent
} from '@mui/material';
import { useAuth } from '../../../hooks/useAuth';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  accountMenuPopover: {
    zIndex: 1400
  },

  accountCard: {
    width: '9.96vw',
    height: '14.6vh',
    backgroundColor: '#0B4759',
    color: 'white',
    paddingTop: '8px'
  },

  accountText: {
    padding: '0px 24px 0px 16px',
    font: 'normal normal normal 24px/35px Poppins',
    color: '#FFFFFF',
    textDecoration: 'none',
    cursor: 'pointer'
  },
  logoutText: {
    padding: '24px 0px 0 16px',
    font: 'normal normal normal 24px/35px Poppins',
    color: '#FFFFFF',
    textDecoration: 'none',
    cursor: 'pointer'
  }
  /* [theme.breakpoints.between('md', 1800)]: {
    accountCard: {
      width: '11.5vw',
      height: '19.5vh',
      paddingTop: '8px'
    },
    accountText: {
      font: 'normal normal normal 20px/25px Poppins'
    },
    logoutText: {
      font: 'normal normal normal 20px/25px Poppins'
    }
  }. */
});

/**
 * @summary AccountMenu component opens the Account menu on clicking account icon button
 * @param {object} props React component props
 */
const AccountMenu = props => {
  const { isAccountMenuOpen, anchorEl, onClose } = props;
  const { logout } = useAuth();
  const classes = useStyles();

  return (
    <Popover
      className={classes.accountMenuPopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isAccountMenuOpen}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <Card className={classes.accountCard}>
        <CardContent>
          {/* <Typography
            component={Link}
            to="/home/account"
            className="accountText"
          >
            Account
          </Typography> */}

          <Typography
            className={classes.logoutText}
            style={{ color: 'balck', cursor: 'pointer' }}
            onClick={logout}
          >
            Logout
          </Typography>
        </CardContent>
      </Card>
    </Popover>
  );
};

AccountMenu.propTypes = {
  isAccountMenuOpen: PropTypes.bool.isRequired
};

export default AccountMenu;
