import { Grid, Modal, Typography } from '@mui/material';
import React, { Component } from 'react';
import { ColorButton } from '../../hoc/PaletteComponent';
import './Customer.css';
import AddCustomer from './AddCustomer';
import DeleteCustomer from './DeleteCustomer';
import TableHOC from '../../hoc/Table';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchBar from '../../hoc/SearchBar';
import NoData from '../../hoc/noData';
import {
  GET_ALL_OEM,
  VALIDATE_OEM_OTP,
  SEND_OTP_TO_USER,
  UPDATE_VALIDATE_STATUS,
  DELETE_CUSTOMER
} from './queryCustomerPage';
import withApollo from '../../client/withApollo';
import { CustomValidate } from '../../hoc/CustomComponents';
import withSharedSnackbar from '../../hoc/Snackbar/withSharedSnackbar';

// Columns for data table
const colName = [
  {
    key: '1',
    columName: 'oemName',
    name: 'OEM Name',
    sortEnable: true,
    sortOrder: 'descending'
  },
  {
    key: '2',
    columName: 'oemCode',
    name: 'OEM Code',
    sortEnable: false,
    sortOrder: 'descending'
  },
  {
    key: '3',
    columName: 'oemEmail',
    name: 'OEM Email',
    sortEnable: true,
    sortOrder: 'descending'
  },
  {
    key: '4',
    columName: 'phoneNumber',
    name: 'Phone Number',
    sortEnable: false,
    sortOrder: 'descending'
  },
  {
    key: '5',
    columName: 'adress1',
    name: 'Address 1',
    sortEnable: true,
    sortOrder: 'descending'
  },
  {
    key: '6',
    columName: 'adress2',
    name: 'Address 2',
    sortEnable: true,
    sortOrder: 'descending'
  },
  {
    key: '7',
    columName: 'bankName',
    name: 'Bank Name',
    sortEnable: true,
    sortOrder: 'descending'
  },
  {
    key: '8',
    columName: 'accountHolder',
    name: 'Account Holder',
    sortEnable: true,
    sortOrder: 'descending'
  },
  {
    key: '9',
    columName: 'accountNumber',
    name: 'Account Number',
    sortEnable: true,
    sortOrder: 'descending'
  },
  {
    key: '10',
    columName: 'ifsc',
    name: 'IFSC',
    sortEnable: true,
    sortOrder: 'descending'
  },
  {
    key: '11',
    columName: 'gst',
    name: 'GST',
    sortEnable: true,
    sortOrder: 'descending'
  },
  {
    key: '12',
    columName: 'pan',
    name: 'PAN',
    sortEnable: true,
    sortOrder: 'descending'
  },
  {
    key: '13',
    columName: 'countryName',
    name: 'Country',
    sortEnable: true,
    sortOrder: 'descending'
  },
  {
    key: '14',
    columName: 'stateName',
    name: 'State',
    sortEnable: true,
    sortOrder: 'descending'
  },
  {
    key: '15',
    columName: 'cityName',
    name: 'City',
    sortEnable: true,
    sortOrder: 'descending'
  },
  {
    key: '16',
    columName: 'pinCode',
    name: 'Pincode',
    sortEnable: true,
    sortOrder: 'descending'
  },
  {
    key: '17',
    columName: 'userId',
    name: 'User ID',
    sortEnable: true,
    sortOrder: 'descending'
  },
  {
    key: '18',
    columName: 'logo',
    name: 'Logo',
    sortEnable: true,
    sortOrder: 'descending'
  },
  {
    key: '19',
    columName: 'ownerId',
    name: 'Owner Id',
    sortEnable: true,
    sortOrder: 'descending'
  },
  {
    key: '20',
    columName: 'status',
    name: 'Status',
    sortEnable: true,
    sortOrder: 'descending'
  },
  {
    key: '21',
    columName: 'otpValidation',
    name: 'OTP Validation',
    sortEnable: true,
    sortOrder: 'descending'
  },
  {
    key: '22',
    columName: 'actions',
    name: 'Actions',
    sortEnable: true,
    sortOrder: 'descending'
  }
];

class Customer extends Component {
  state = {
    openCustomerDialog: false,
    oemResponse: null,
    openOtpCard: false,
    otpInput: '',
    otpPhoneNumber: '',
    oemId: null,
    isOtpBeingSent: false,
    isResendOtpBeingSent: false,
    isResendOtpSent: false,
    isDeleteModalOpen: false,
    tallyCustomerStatus: null
  };

  /**
   * @summary This lifecycle method will be called at the time our component will render
   */
  componentDidMount = async () => {
    await this.getOemCustomer();
  };

  sendOtp = async id => {
    this.setState({ isOtpBeingSent: true });

    let sendOtpResponse = await this.props.client.mutate({
      mutation: SEND_OTP_TO_USER,
      variables: {
        id: id
      }
    });

    let res = sendOtpResponse.data.sendOtpToUser;

    this.setState({ isOtpBeingSent: false });

    return res;
  };

  handleResendOTP = async () => {
    this.setState({
      isResendOtpBeingSent: true
    });

    let sendOtpResponse = await this.props.client.mutate({
      mutation: SEND_OTP_TO_USER,
      variables: {
        id: this.state.oemId
      }
    });

    let res = sendOtpResponse.data.sendOtpToUser;

    this.setState({
      isResendOtpBeingSent: false,
      isResendOtpSent: true
    });

    return res;
  };

  handleOtpButtonClick = async (id, phoneNumber) => {
    this.setState({
      openOtpCard: true,
      otpPhoneNumber: phoneNumber,
      oemId: id
    });

    await this.sendOtp(id);
  };

  handleOptCardCancel = () => {
    this.setState({
      openOtpCard: false,
      otpInput: '',
      otpPhoneNumber: '',
      oemId: null,
      isOtpBeingSent: false,
      isResendOtpBeingSent: false,
      isResendOtpSent: false
    });
  };

  handleOtpInput = e => {
    this.setState({ otpInput: e });
  };

  checkOtp = otpData => {
    const regex = /^\d+$/;
    if (regex.test(otpData) == true) {
      return true;
    } else {
      return false;
    }
  };

  handleValidateClick = async () => {
    let isOtpFormatCorrect = await this.checkOtp(
      this.state.otpInput
    );

    if (isOtpFormatCorrect == true) {
      try {
        let response = await this.props.client.query({
          variables: {
            otp: parseInt(this.state.otpInput),
            oemId: this.state.oemId
          },
          query: VALIDATE_OEM_OTP,
          errorPolicy: 'all'
        });

        if (response.data.validateOemOtp == true) {
          let updateStatusResponse = await this.props.client.mutate({
            mutation: UPDATE_VALIDATE_STATUS,
            variables: {
              id: this.state.oemId
            }
          });

          await this.getOemCustomer();
          this.setState({
            openOtpCard: false,
            otpInput: '',
            otpPhoneNumber: '',
            oemId: null,
            isOtpBeingSent: false,
            isResendOtpBeingSent: false,
            isResendOtpSent: false
          });
          this.props.openSnackbar('OTP Verified Successfully.');
        } else {
          this.props.openSnackbar('Please enter correct OTP.');
        }
      } catch (error) {
        if (this.state.otpInput == '') {
          this.props.openSnackbar('Please enter correct OTP.');
        } else {
          this.setState({ openOtpCard: false });
          this.props.openSnackbar('Something went wrong.');
        }
        console.log(error, 'error');
      }
    } else {
      this.props.openSnackbar('OTP should contain numbers only.');
    }
  };

  /**
   * @function
   * @summary This function parsed the oemResponse to configure with data table
   */
  parseOemData = response => {
    // console.log(response, 'response:-');
    let newOemResponse = [];
    let array = [];
    for (let res of response) {
      array = [
        {
          column: 1,
          value: res.oemName ? res.oemName : '',
          customComponent: false
        },
        {
          column: 2,
          value: res.oemCode ? res.oemCode : ''
        },
        {
          column: 3,
          value: res.oemEmail ? res.oemEmail : ''
        },
        {
          column: 4,
          value: res.phoneNumber ? res.phoneNumber : ''
        },
        {
          column: 5,
          value: res.addressLine1 ? res.addressLine1 : ''
        },
        {
          column: 6,
          value: res.addressLine2 ? res.addressLine2 : ''
        },
        {
          column: 7,
          value: res.bankName ? res.bankName : ''
        },
        {
          column: 8,
          value: res.accHoldingPerson ? res.accHoldingPerson : ''
        },
        {
          column: 9,
          value: res.accNumber ? res.accNumber : ''
        },
        {
          column: 10,
          value: res.ifsc ? res.ifsc : ''
        },
        {
          column: 11,
          value: res.gst ? res.gst : ''
        },
        {
          column: 12,
          value: res.pan ? res.pan : ''
        },
        {
          column: 13,
          value: res.countryId ? res.countryName : ''
        },
        {
          column: 14,
          value: res.stateId ? res.stateName : ''
        },
        {
          column: 15,
          value: res.city ? res.cityName : ''
        },
        {
          column: 16,
          value: res.pincode ? res.pincode : ''
        },
        {
          column: 17,
          value: res.userId ? res.userId : ''
        },
        {
          column: 18,
          value: res.logo ? res.logo : ''
        },
        {
          column: 19,
          value: res.ownerId ? res.ownerId : ''
        },
        {
          column: 20,
          value: res.status ? res.status : '',
          fillColor:
            res.status && res.status == 'ACTIVE'
              ? '#E0FFF3'
              : res.status == 'INACTIVE'
              ? '#CFD1D1'
              : res.status == 'APPROVAL PENDING'
              ? '#CFD1D1'
              : '',
          textColor: '#FF8082'
        },
        {
          column: 21,
          customComponent: [
            <ColorButton
              disabled={
                res.status == 'ACTIVE' ||
                res.status == 'APPROVAL PENDING' ||
                res.status == null
              }
              variant="outlined"
              onClick={() =>
                this.handleOtpButtonClick(res.id, res.phoneNumber)
              }
              className="buttonOutlined"
              style={{
                maxWidth: '180px',
                maxHeight: '30px'
              }}
            >
              <Typography variant="subtitle1">
                Validate OTP
              </Typography>
            </ColorButton>
          ]
        },
        {
          column: 22,
          customComponent: [
            <EditIcon
              style={{ cursor: 'pointer', paddingRight: '16px' }}
              onClick={() => this.handleEdit(res.id, res.status)}
            />,
            <DeleteIcon
              style={{ cursor: 'pointer', color: 'red' }}
              onClick={() => this.openDeleteModal(res.id)}
            />
          ]
        }
      ];
      newOemResponse.push(array);
    }
    return newOemResponse;
  };

  /**
   * @function
   * @summary This function get the all data of customer
   */
  getOemCustomer = async () => {
    try {
      const response = await this.props.client.query({
        query: GET_ALL_OEM,
        fetchPolicy: 'network-only'
      });
      const newOemResponse = this.parseOemData(response.data.getOem);
      // console.log(newOemResponse, 'newOemResponse');
      this.setState({ oemResponse: newOemResponse });
    } catch (error) {
      console.log(error, 'error');
    }
  };

  /**
   * @function
   * @summary This function handle the customer dialog to open
   */
  handleAddCustomer = () => {
    this.setState({ openCustomerDialog: true });
  };

  /**
   * @function
   * @summary This function handle the customer dialog to close
   */
  handleCustomerClose = async () => {
    this.setState({ openCustomerDialog: false });
    await this.getOemCustomer();
  };

  /**
   * @function
   * @summary This function handle the customer edit
   */
  handleEdit = (oemId, status) => {
    this.setState({
      oemId: oemId,
      tallyCustomerStatus: status,
      openCustomerDialog: true
    });
  };

  /**
   * @function
   * @summary This function handle the customer delete
   */
  openDeleteModal = oemId => {
    this.setState({ isDeleteModalOpen: true, oemId });
  };

  handleDeleteModalClose = async () => {
    this.setState({ isDeleteModalOpen: false });
  };

  deleteCustomer = async () => {
    let deleteCustomerResponse = await this.props.client.mutate({
      mutation: DELETE_CUSTOMER,
      variables: {
        oemId: parseInt(this.state.oemId)
      }
    });

    let res = deleteCustomerResponse.data.deactivateOemStatus;

    this.setState({
      isDeleteModalOpen: false
    });

    if (res == true) {
      this.props.openSnackbar('Customer deleted successfully.');
    } else {
      this.props.openSnackbar('Customer deletion failed.');
    }

    await this.getOemCustomer();
  };

  render() {
    const {
      oemId,
      tallyCustomerStatus,
      openCustomerDialog,
      oemResponse,
      isOtpBeingSent,
      openOtpCard,
      otpInput,
      otpPhoneNumber,
      isResendOtpBeingSent,
      isResendOtpSent,
      isDeleteModalOpen
    } = this.state;
    return (
      <Grid container>
        <Grid item xs={12}>
          {openCustomerDialog == true ? (
            <AddCustomer
              open={openCustomerDialog}
              onClose={this.handleCustomerClose}
              oemId={oemId}
              tallyCustomerStatus={tallyCustomerStatus}
            />
          ) : null}

          <DeleteCustomer
            open={isDeleteModalOpen}
            onClose={this.handleDeleteModalClose}
            deleteCustomer={this.deleteCustomer}
          />
          <Grid
            container
            justifyContent="space-between"
            style={{ paddingBottom: '16px' }}
          >
            <Grid item style={{ paddingLeft: '16px' }}>
              <Typography className="header">Customers</Typography>
            </Grid>
            <Grid item style={{ paddingRight: '24px' }}>
              <ColorButton
                variant="contained"
                onClick={this.handleAddCustomer}
                className="button"
              >
                <Typography
                  style={{
                    font: 'normal normal 600 16px/22px Poppins'
                  }}
                >
                  Add New Customer
                </Typography>
              </ColorButton>
            </Grid>
          </Grid>
          {oemResponse && oemResponse.length ? (
            <Grid container className="tableContainer">
              <Grid item xs={12}>
                <Grid container>
                  <Grid item className="searchBar">
                    <SearchBar placeholder="Search for table content" />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      style={{ padding: '16px 16px' }}
                    >
                      <TableHOC
                        columns={colName}
                        data={oemResponse}
                        rowsPerPage={5}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <NoData />
          )}
          <Grid>
            {openOtpCard ? (
              <CustomValidate
                open={openOtpCard}
                otpInput={otpInput}
                otpPhoneNumber={otpPhoneNumber}
                handleOnChange={this.handleOtpInput}
                handleResendOTP={this.handleResendOTP}
                handleCancel={this.handleOptCardCancel}
                handleValidateClick={this.handleValidateClick}
                isOtpBeingSent={isOtpBeingSent}
                isResendOtpBeingSent={isResendOtpBeingSent}
                isResendOtpSent={isResendOtpSent}
              />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withApollo(withSharedSnackbar(Customer));
