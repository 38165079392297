import { Button } from '@mui/material';
import { styled } from '@mui/system';

export const ColorButton = styled(Button)({
  textTransform: 'none',
  width: '100%',
  textAlign: 'center',
  font: 'normal normal 500 22px/30px poppins',
  letterSpacing: '0px',
  color: '#57B793',
  opacity: 1,
  variant: 'contained',
  background: '#E0FFF3 0% 0% no-repeat padding-box',
  border: '1px solid #57B793',
  borderRadius: '20px',
  ':disabled': {
    background: '#E0FFF3 0% 0% no-repeat padding-box',
    opacity: 0.6
  }
});

export const ColorOutlinedButton = styled(Button)({
  border: '2px solid #333333',
  textAlign: 'center',
  borderRadius: '20px',
  font: 'normal normal 500 22px/30px poppins',
  letterSpacing: '0px',
  color: '#333333',
  variant: 'contained',
  opacity: 1,
  ':disabled': {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    opacity: 0.4,
    border: '2px solid #333333'
  }
});
