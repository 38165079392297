import { Drawer, Icon, List, ListItem } from '@mui/material';
import React from 'react';
import { Pages } from '../pagesConfig';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  test: {
    paddingTop: '200px'
  },
  drawer: {
    marginTop: '60px'
    // zIndex: theme.zIndex.appBar - 1
  },
  list: {
    // backgroundColor: 'red'
    width: 80
  },
  listItem: {
    padding: '30px'
  },
  icon: {
    // backgroundColor: 'green'
  }
});

export default function MenuDrawer() {
  const navigate = useNavigate();
  const classes = useStyles();
  function handlePath(path) {
    if (path) {
      navigate(path);
    }
  }
  return (
    // <div className={classes.test}>hi</div>
    <Drawer
      className={classes.drawer}
      open={true}
      variant="persistent"
      PaperProps={{ elevation: 9 }}
      style={{
        background: '#FFFFFE 0% 0% no-repeat padding-box',
        opacity: 1
      }}
    >
      <List
        dense
        className={classes.list}
        //  style={{ width: '100px' }}
        style={{ marginTop: '80px' }}
      >
        {Pages.map((page, index) => {
          // console.log(page);
          return (
            <ListItem
              button
              key={index}
              //   className={classes.listItem}
              style={{ padding: '18px' }}
              onClick={() => handlePath(page.path)}
            >
              <Icon
                className={classes.icon}
                style={{ width: '100%', height: '100%' }}
              >
                <img alt="" src={page.icon} />
              </Icon>
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
}
