/**
 * @utils
 * @summary This Page for some functions used for Customers Creation
 */

export const parseCategoriesData = categoryData => {
  let categoryDataArray = [];

  categoryData[0].map(eachCategory => {
    console.log('eachCategory', eachCategory);

    let subCategoriesArray = getFeatureArray(eachCategory.features);

    let newObject = {
      name: eachCategory.name,
      categoryId: eachCategory.id,
      subCategories: subCategoriesArray,
      isPlanRecurring: false,
      isBillingTcuWise: false,
      currencyId: '',
      currencyName: '',
      tcuBillingId: '',
      tcuBillingMode: '',
      simBillingId: '',
      simBillingMode: '',
      tcuPacket: '',
      simPacket: '',
      tcuTrack: '',
      simTrack: '',
      tcuBillingPeriodId: '',
      tcuBillingPeriod: '',
      simBillingPeriodId: '',
      simBillingPeriod: '',
      tcuTaxPercentId: '',
      tcuTaxPercent: '',
      simTaxPercentId: '',
      simTaxPercent: '',
      tcuPeriodCharge: '',
      simPeriodCharge: '',
      tcuFreeDays: '',
      simFreeDays: '',
      tcuExpiryPeriod: '',
      simExpiryPeriod: '',
      tcuCreditDays: '',
      simCreditDays: '',
      isTcuBilingModeValid: true,
      isSimBilingModeValid: true,
      isTcuPacketValid: true,
      isSimPacketValid: true,
      isTcuTrackValid: true,
      isSimTrackValid: true,
      isTcuBillingPeriodValid: true,
      isSimBillingPeriodValid: true,
      isTcuTaxPercentValid: true,
      isSimTaxPercentValid: true,
      isTcuPeriodChargeValid: true,
      isSimPeriodChargeValid: true,
      isTcuFreeDaysValid: true,
      isSimFreeDaysValid: true,
      isTcuExpiryPeriodValid: true,
      isSimExpiryPeriodValid: true,
      isTcuCreditDaysValid: true,
      isSimCreditDaysValid: true
    };
    categoryDataArray.push(newObject);
  });

  return categoryDataArray;
};

const getFeatureArray = featureArray => {
  let subCategoriesArray = [];

  featureArray.map(eachFeature => {
    let newFeatureObject = {
      name: eachFeature.name,
      featureId: eachFeature.id,
      read: false,
      create: false,
      edit: false,
      delete: false
    };
    subCategoriesArray.push(newFeatureObject);
  });

  return subCategoriesArray;
};
