import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: { main: '#2B8564' }
  },
  typography: {
    fontFamily: ['"Poppins"', 'sans-serif'].join(',')
  }
});
