import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthProvider } from './hooks/useAuth';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql
} from '@apollo/client';
import App from './App';
import { theme } from './theme';
import { client } from './client';
import { Helmet } from 'react-helmet';
import FaviconIcon from './assets/favicon.ico';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <React.Fragment>
    <Helmet>
      <title>OEM-ZSA</title>
      <link rel="shortcut icon" href={FaviconIcon} />
    </Helmet>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <App style={{ backgroundColor: '#F3F3F3' }} />
          </ThemeProvider>
        </AuthProvider>
      </BrowserRouter>
    </ApolloProvider>
  </React.Fragment>
);
