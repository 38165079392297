/**
 * @module utils/getLoginId
 * @summary Exports the `getLoginId` function
 */

/**
 * This function fetches the value for `loginId` key from browser's
 * `localStorage` or `sessionStorage`
 * @returns The loginId of the current user
 * @summary getLoginId fetches the loginId from the browser storage
 */
function getLoginId() {
  return parseInt(localStorage.getItem('loginId'), 10);
}

/**
 * @module utils/getOwnerId
 * @summary Exports the `getLoginId` function
 */

/**
 * This function fetches the value for `ownerId` key from browser's
 * `localStorage`
 * @returns The ownerId of the current user
 * @summary getOwnerId fetches the ownerId from the browser storage
 */

function getOwnerId() {
  return parseInt(localStorage.getItem('ownerId'), 10);
}

/**
 * @module utils/getUserName
 * @summary Exports the `getLoginId` function
 */

/**
 * This function fetches the value for `userName` key from browser's
 * `localStorage`
 * @returns The userName of the current user
 * @summary getUserName fetches the userName from the browser storage
 */

function getUserName() {
  return parseInt(localStorage.getItem('userName'), 10);
}

module.exports = { getLoginId, getOwnerId, getUserName };
