import * as React from 'react';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import './login.css';
import {
  InputAdornment,
  IconButton,
  FormHelperText,
  Hidden
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import { ColorButton } from '../../hoc/PaletteComponent';
import FavoriteIcon from '@mui/icons-material/Favorite';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Logo from '../../assets/zeliot-logo.png';
import { useApolloClient } from '@apollo/client';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import Image1 from '../../assets/macbook_mockup_banner.png';
import Image2 from '../../assets/MicrosoftTeams-image2.png';
import Image3 from '../../assets/MicrosoftTeams-image3.png';
import { gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const LoginQuery = gql`
  mutation ($username: String!, $password: String!) {
    login(username: $username, password: $password)
  }
`;

export const LoginPage = () => {
  const client = useApolloClient();
  const [showPassword, setShowPassword] = useState(false);
  let [loginValue, setLoginValue] = useState('');
  let [passwordValue, setPasswordValue] = useState('');
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { login, user } = useAuth();

  const handleLoginValueChange = e => {
    setLoginValue(e.target.value);
  };

  const handlePasswordChange = e => {
    setPasswordValue(e.target.value);
  };

  const handlePassword = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };
  const handleSubmit = async event => {
    event.preventDefault();
    try {
      const result = await client.mutate({
        variables: { username: loginValue, password: passwordValue },
        mutation: LoginQuery,
        errorPolicy: 'all'
      });
      if (result.data && result.data.login) {
        // localStorage.setItem('id_token', result.data.login);
        // navigate('/dashboard', { replace: true });
        login(result.data.login);
      } else {
        setError(true);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const settings = {
    adaptiveHeight: true,
    variableHeight: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true
  };

  return (
    <Grid container className="MainContainer">
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} lg={4} className="ContainerLeft">
            <Grid container>
              <Grid item xs={12}>
                <img src={Logo} className="Logo" />
              </Grid>
              <Grid item xs={12} className="Form">
                <form>
                  <Grid container spacing={5}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={loginValue}
                        id="email"
                        onChange={handleLoginValueChange}
                        name="loginValue"
                        placeholder="Enter your username here"
                        InputProps={{
                          style: {
                            color: 'white'
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon
                                style={{ fill: '#ffffff' }}
                              />
                            </InputAdornment>
                          )
                        }}
                        className="TextField"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={passwordValue}
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        onChange={handlePasswordChange}
                        id="password"
                        placeholder="Enter your password here"
                        InputProps={{
                          style: {
                            color: 'white'
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockIcon className="IconColor" />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handlePassword}>
                                {showPassword ? (
                                  <VisibilityIcon className="IconColor" />
                                ) : (
                                  <VisibilityOffIcon className="IconColor" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        className="TextField"
                      />
                      <FormHelperText className="HelperText">
                        {error
                          ? 'Invalid username and password'
                          : ''}
                      </FormHelperText>
                      <Grid item xs={12} className="ButtonPadding">
                        <ColorButton
                          className="ButtonStyle"
                          onClick={handleSubmit}
                          variant="contained"
                        >
                          <Typography className="TextColor">
                            Login
                          </Typography>
                        </ColorButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              <Grid item xs={12}>
                <Grid container className="Footer">
                  <Grid item xs={12}>
                    <Typography>zeliot.com</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container display="flex" spacing={1}>
                      <Grid item>
                        <Typography>Made with{''}</Typography>
                      </Grid>
                      <Grid item>
                        <FavoriteIcon
                          fontSize="small"
                          className="FavIcon"
                        />
                      </Grid>
                      <Grid item>
                        <Typography>by Team Zeliot</Typography>{' '}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Hidden mdDown>
            <Grid item lg={8} className="ContainerRight">
              <Grid container>
                <Grid item xs={12}>
                  <Slider {...settings}>
                    <img src={Image1} />
                    <img src={Image2} />
                    <img src={Image3} />
                  </Slider>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </Grid>
  );
};
