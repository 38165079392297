import React, { useState } from 'react';
import {
  OutlinedInput,
  InputAdornment,
  FormControl,
  FormHelperText,
  Autocomplete,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  Checkbox,
  Grid,
  Typography,
  Link,
  Button,
  Box,
  CircularProgress
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import {
  ColorButton,
  ColorOutlinedButton
} from './PaletteComponent';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import {
  DateTimePicker,
  LocalizationProvider
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
  paper: { minWidth: '850px', minHeight: '200px' },
  content: {
    font: 'normal normal 500 26px/34px poppins',
    textAlign: 'center',
    marginTop: '16px'
  },
  textField: {
    background: '#EFEFEF 0% 0% no-repeat padding-box',
    border: '1px solid #DEDEDF',
    borderRadius: '8px',
    opacity: 1,
    font: 'normal normal medium 16px/20px poppins',
    letterSpacing: '0px',
    color: '#333333'
  },

  label: {
    textAlign: 'left',
    font: 'normal normal normal 14px/20px Poppins',
    letterSpacing: '0px',
    color: '#707070'
  },

  linkTextStyle: {
    color: 'blue'
  }
});

export function CustomTextField(props) {
  const classes = useStyles();
  const {
    value,
    onChange,
    placeholder,
    type,
    label,
    onBlur,
    error,
    disableFutureDates,
    name,
    disabled
  } = props;
  return (
    <FormControl sx={{ m: 1 }} fullWidth={true}>
      <FormHelperText
        className={classes.label}
        // style={{ font: 'normal normal 500 13px/20px poppins' }}
      >
        {label}
      </FormHelperText>
      <OutlinedInput
        style={{ borderRadius: '8px' }}
        disabled={disabled}
        value={value}
        type={type}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        fullWidth
        onBlur={onBlur}
        error={error}
        inputProps={
          type === 'datetime-local' && disableFutureDates
            ? { min: new Date().toISOString().slice(0, 16) }
            : ''
        }
        endAdornment={
          <InputAdornment position="end">
            {type == 'text' || type == 'number' ? (
              <EditIcon fontSize="medium" />
            ) : null}
            {type == 'select' ? (
              <ExpandMoreIcon fontSize="medium" />
            ) : null}
            {/* {type == 'datetime-local' ? (
              <CalendarMonthIcon fontSize="medium" />
            ) : null} */}
            {type === 'file' ? (
              <CloudUploadIcon fontSize="medium" />
            ) : null}
          </InputAdornment>
        }
        className={classes.textField}
      ></OutlinedInput>
    </FormControl>
  );
}

export function CustomSelect(props) {
  const classes = useStyles();

  const {
    value,
    options,
    handleSelectChange,
    onChange,
    inputValue,
    label,
    placeholder,
    name,
    onBlur,
    error
  } = props;
  // console.log(options, 'options');
  return (
    <>
      <FormControl sx={{ m: 1 }} fullWidth={true}>
        <FormHelperText
          className={classes.label}
          style={{ font: 'normal normal 500 13px/20px poppins' }}
        >
          {label}
        </FormHelperText>
        <Autocomplete
          options={options || []}
          name={name}
          value={value}
          popupIcon={<ExpandMoreIcon fontSize="large" />}
          onBlur={onBlur}
          onChange={handleSelectChange}
          inputValue={inputValue}
          autoHighlight
          renderInput={params => {
            return (
              <TextField
                name={name}
                value={inputValue}
                fullWidth
                {...params}
                placeholder={placeholder}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password'
                }}
                onChange={onChange}
                error={error}
              />
            );
          }}
          className={classes.textField}
        ></Autocomplete>
      </FormControl>
    </>
  );
}

export function CustomCheckbox(props) {
  const { checked, onChange } = props;
  return (
    <Grid item>
      <Checkbox
        checked={checked}
        onChange={onChange}
        icon={<CircleIcon />}
        checkedIcon={<CheckCircleIcon />}
      />
    </Grid>
  );
}

export function CustomDateTimePicker(props) {
  const [open, setOpen] = useState(false);
  const {
    value,
    handleChange,
    disablePastDates,
    label,
    placeholder,
    error,
    onBlur
  } = props;

  function handleDateChange(e) {
    // console.log(e, 'value');
    let unix_seconds = new Date(e).getTime() / 1000;
    // console.log(unix_seconds, ' unix_seconds');
    handleChange(unix_seconds);
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormControl fullWidth={true}>
        <FormHelperText
          // className={classes.label}
          style={{ font: 'normal normal 500 13px/20px poppins' }}
        >
          {label}
        </FormHelperText>
        <DateTimePicker
          inputProps={{
            style: {
              background: '#EFEFEF 0% 0% no-repeat padding-box',
              border: '1px solid #DEDEDF'
            },
            placeholder: placeholder
          }}
          placeholderText="Please select a date"
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          value={value ? value * 1000 : null}
          onChange={handleDateChange}
          renderInput={params => (
            <TextField
              fullWidth
              {...params}
              onClick={e => setOpen(true)}
              error={error}
              onBlur={onBlur}
            />
          )}
        />
      </FormControl>
    </LocalizationProvider>
  );
}

export function CustomDialogBox(props) {
  const classes = useStyles();
  const {
    open,
    content,
    negativeResponseHandler,
    negativeResponseButtonText,
    positiveResponseHandle,
    positiveResponseButtonText
  } = props;
  return (
    <Dialog open={open} classes={{ paper: classes.paper }}>
      <DialogContent className={classes.content}>
        {content}
      </DialogContent>
      <DialogActions style={{ marginBottom: '16px' }}>
        <ColorOutlinedButton
          onClick={negativeResponseHandler}
          style={{ marginRight: '5px' }}
        >
          {negativeResponseButtonText}
        </ColorOutlinedButton>
        <ColorButton onClick={positiveResponseHandle}>
          {positiveResponseButtonText}
        </ColorButton>
      </DialogActions>
    </Dialog>
  );
}

export function CustomValidate(props) {
  const classes = useStyles();
  const {
    open,
    otpInput,
    otpPhoneNumber,
    handleOnChange,
    handleResendOTP,
    handleCancel,
    handleValidateClick,
    isOtpBeingSent,
    isResendOtpBeingSent,
    isResendOtpSent
  } = props;

  console.log('props.isOtpBeingSent=====', props.isOtpBeingSent);

  return (
    <Dialog open={open}>
      <DialogContent>
        {isOtpBeingSent ? (
          <Grid item xs={12}>
            <Box sx={{ justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          <Grid
            container
            justifyContent="center"
            align="center"
            style={{ padding: '1vw' }}
            spacing={4}
          >
            <Grid item xs={12}>
              <Typography
                style={{
                  font: 'normal normal bold 24px/32px Poppins'
                }}
              >
                Please enter the OTP to authenticate
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ font: 'normal normal 600 16px/20px Poppins' }}
            >
              <Typography>
                `An OTP has been sent to *******
                {otpPhoneNumber.slice(7)}`
              </Typography>
            </Grid>
            <Grid item>
              <OtpInput
                value={otpInput}
                onChange={handleOnChange}
                numInputs={6}
                separator={<span> </span>}
                inputStyle={{
                  margin: '10px',
                  width: '34px',
                  height: '50px'
                }}
              />
            </Grid>

            <Grid item xs={12}>
              {isResendOtpBeingSent ? (
                <Grid item xs={12}>
                  <Box sx={{ justifyContent: 'center' }}>
                    <CircularProgress />
                  </Box>
                </Grid>
              ) : !isResendOtpSent ? (
                <Link
                  onClick={handleResendOTP}
                  style={{
                    color: ' #333333',
                    textDecoration: 'none ',
                    cursor: 'pointer'
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.linkTextStyle}
                  >
                    Resend One-Time Password
                  </Typography>
                </Link>
              ) : (
                <Typography
                  variant="subtitle1"
                  className={classes.linkTextStyle}
                >
                  OTP Resent Successfully. Please enter new OTP.
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                display="flex-end"
                justifyContent="space-between"
                spacing={2}
              >
                <Grid item>
                  <ColorButton
                    style={{
                      minWidth: '180px'
                    }}
                    onClick={handleCancel}
                  >
                    <Typography variant="subtitle1">
                      Cancel
                    </Typography>
                  </ColorButton>
                </Grid>
                <Grid item>
                  <ColorButton
                    style={{
                      minWidth: '180px'
                    }}
                    onClick={() => handleValidateClick()}
                  >
                    <Typography variant="subtitle1">
                      Verify
                    </Typography>
                  </ColorButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}
export function ValidateButton(props) {
  console.log(props);
  const { onClick } = props;
  return (
    <button
      style={{
        boxShadow: '0px 0px 20px #00000029',
        border: '1px solid #777777',
        borderRadius: '6px',
        opacity: 1,
        padding: '0px 20px 0px 20px',
        width: '100%'
      }}
      onClick={onClick}
    >
      Validate
    </button>
  );
}
