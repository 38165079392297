import React from 'react';
import { ColorOutlinedButton } from './PaletteComponent';
import { Grid, Typography, InputAdornment } from '@mui/material';
import image from '../assets/Upload-rafiki.svg';
import cloudDownload from '../assets/cloud-download.svg';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export default function NoData() {
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={6}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            item
            xs={12}
            style={{
              paddingTop: '15.4vh',
              paddingLeft: '3.2vw'
            }}
          >
            <img
              src={image}
              // style={{
              //   height: '75.5vh',
              //   width: '25vw'
              // }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          paddingTop: '32.17vh',
          paddingLeft: '5vw'
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          spacing={3}
          alignItems="center"
          style={{
            width: '23.1vw',
            height: '6.4vh'
          }}
        >
          <Grid item xs={12}>
            <Typography
              style={{
                // width: '23.1vw',
                // height: '6.4vh',
                font: 'normal normal 600 24px/32px Poppins',
                letterSpacing: '0px',
                color: '#212121',
                opacity: 1
              }}
            >
              No Details found. Please upload the details using this
              button
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ColorOutlinedButton
              style={{
                background: '#E0FFF3 0% 0% no-repeat padding-box',
                border: '1px solid #57B793',
                borderRadius: '8px',
                opacity: 1
              }}
              InputProps={{
                startAdorment: (
                  <InputAdornment position="start">
                    <CloudDownloadIcon
                      style={{
                        background:
                          '#57B793 0% 0% no-repeat padding-box',
                        opacity: 1
                      }}
                    />
                  </InputAdornment>
                )
              }}
            >
              <img
                src={cloudDownload}
                style={{
                  paddingRight: '5px',
                  paddingLeft: '5px'
                }}
              />
              Download Template
            </ColorOutlinedButton>
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{
                // height: '3.17vh',
                // width: '12.1vw',
                font: 'normal normal 600 12px/16px Poppins',
                letterSpacing: '0px',
                color: '#707070',
                opacity: 1
              }}
            >
              The template is in .xlss format. Add the details in the
              sheet and reupload.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
