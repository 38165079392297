import React from 'react';
import { Grid } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = props => {
  const { id, placeholder, onChange } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          id={id}
          fullWidth={true}
          placeholder={placeholder}
          onChange={onChange}
          variant="outlined"
          style={{
            backgroundColor: '#F3F3F3',
            border: '2px solid #CFD1D1',
            borderRadius: '8px'
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  style={{
                    paddingLeft: '3.23vw',
                    paddingRight: '1.01vw'
                  }}
                />
              </InputAdornment>
            )
          }}
        />
      </Grid>
    </Grid>
  );
};

export default SearchBar;
