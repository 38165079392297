/**
 * @module TableHOC
 * @summary This module exports the table component
 */
import React, { useState } from 'react';
import {
  Grid,
  Table,
  Paper,
  Select,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  MenuItem,
  Typography,
  TableContainer,
  IconButton,
  FormControl,
  Pagination
} from '@mui/material';

import './Table.css';
import { makeStyles } from '@mui/styles';

// Style overiding
const useStyles = makeStyles({
  formControl: {
    background: '#E0FFF3 0% 0% no-repeat padding-box',
    opacity: 1
  },
  menuPaper: {
    maxHeight: 100
  },
  resultsText: {
    textAlign: 'left',
    font: 'normal normal 500 16px/22px Poppins',
    letterSpacing: '0px',
    color: '#707070',
    opacity: 1
  },
  paginationItem: {
    paddingTop: '16px'
  },
  arrowItem: {
    paddingRight: '40px'
  },
  jumpItem: {
    paddingRight: '24px'
  },
  jumpText: {
    textAlign: 'left',
    font: 'normal normal 500 16px/22px Poppins',
    letterSpacing: '0px',
    color: '#707070',
    opacity: 1,
    paddingRight: '16px'
  }
});

/**
 * @summary AddCustomer component opens the Customer dialog on clicking add customer  button
 * @param {object} props React component props
 * @property {number} currentPage Shows the current page of table
 * @property {number} pageNo Shows the page number of the pagination
 */
const TableHOC = props => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  const { columns, data, rowsPerPage } = props;
  const classes = useStyles();

  const ROWS_PER_PAGE = rowsPerPage ? rowsPerPage : 5;
  const count = data ? (data.length > 0 ? data.length : 0) : 0;
  let remainderData = 0;
  let noOfPages = 0;
  let previousNo = null;
  let nextNumber = null;
  const allValues = data
    ? data.slice(
        currentPage * ROWS_PER_PAGE,
        currentPage * ROWS_PER_PAGE + ROWS_PER_PAGE
      )
    : [];

  if (count) {
    remainderData = count % ROWS_PER_PAGE;
    noOfPages = Math.floor(count / ROWS_PER_PAGE);
  }
  if (remainderData > 0) {
    noOfPages += 1;
  }

  if (currentPage + 1 > 1) {
    previousNo = currentPage;
  }
  if (currentPage + 1 < noOfPages) nextNumber = currentPage + 2;

  /**
   * Change the current page by decreasing it by 1. And set the pageNo of the table
   * @function
   * @summary Changes the page
   */
  const handleChange = (event, value) => {
    setCurrentPage(value - 1);
    setPageNo(value);
  };

  /**
   * handle the change of dropdown select by choosing the page
   * @function
   * @summary Changes the page by selecting the page number on drop down
   */
  const handlePageSelectChange = event =>
    setPageNo(event.target.value);

  /**
   * handle the change of dropdown select by choosing the page
   * @function
   * @summary Changes the page by selecting the page number on drop down
   */
  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
    setPageNo(pageNumber + 1);
  };

  /**
   * rendering the options in dropdown
   * @function
   * @summary All the pages options will be shown ion the dropdown
   */
  const renderOptions = noOfPages => {
    let options = [];
    if (noOfPages) {
      for (let i = 1; i <= noOfPages; i++) {
        options.push(i);
      }
    }
    return options;
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.key}
                      align="center"
                      className="columnHeader"
                    >
                      <Typography>{column.name}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {allValues &&
                  allValues.length &&
                  allValues.map(item => (
                    <TableRow>
                      {item.map(d =>
                        d.customComponent ? (
                          <TableCell
                            align="center"
                            className="content"
                          >
                            {d.customComponent}
                          </TableCell>
                        ) : (
                          <TableCell
                            align="center"
                            style={{ backgroundColor: d.fillColor }}
                            className="content"
                          >
                            <Typography
                              style={{ color: d.textColor }}
                            >
                              {d.value}
                            </Typography>
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {allValues && allValues.length > 0 && (
          <Grid container>
            <Grid item xs={12} className={classes.paginationItem}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography className={classes.resultsText}>
                    {count} Results
                  </Typography>
                </Grid>

                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item className={classes.arrowItem}>
                      <Pagination
                        count={noOfPages}
                        page={pageNo}
                        onChange={handleChange}
                        color="primary"
                      />
                    </Grid>

                    <Grid item className={classes.jumpItem}>
                      <Grid container alignItems="center">
                        <Grid item>
                          <Typography className={classes.jumpText}>
                            Jump To :
                          </Typography>
                        </Grid>
                        <Grid item>
                          {noOfPages >= 1 && (
                            <FormControl
                              variant="outlined"
                              size="small"
                              className={classes.formControl}
                            >
                              <Select
                                id="demo-simple-select-outlined"
                                value={pageNo}
                                onChange={handlePageSelectChange}
                                MenuProps={{
                                  classes: {
                                    paper: classes.menuPaper
                                  }
                                }}
                              >
                                {renderOptions(noOfPages) &&
                                  renderOptions(noOfPages).length &&
                                  renderOptions(noOfPages).map(
                                    (option, index) => (
                                      <MenuItem
                                        key={index}
                                        value={option}
                                        onClick={() => {
                                          handlePageChange(
                                            option - 1
                                          );
                                        }}
                                      >
                                        {option}
                                      </MenuItem>
                                    )
                                  )}
                              </Select>
                            </FormControl>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default TableHOC;
